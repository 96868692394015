import { postWithExceptions } from "api/apiRequest";

export async function submitGenerateApiKey(): Promise<SalesforceMarketingCloudKeyResponseType> {
  return await postWithExceptions(
    "/JourneyBuilderCustomActivity/APIKey/generate",
    {
      param: {},
    }
  );
}

export type SalesforceMarketingCloudKeyResponseType = {
  result: "authenticated" | string;
  apiKey: string;
  endpointUrl: string;
  companyName: string;
};
