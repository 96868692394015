import { post } from "../apiRequest";
import { POST_USER_PROFILE_COUNT } from "../apiPath";
import { AudienceFilterConditionType } from "../../types/BroadcastCampaignType";
import { CountContactsResultType } from "../../types/ProfileSearchType";
import { SortParamType } from "../../container/Contact";

export async function fetchCountContacts(
  filters: AudienceFilterConditionType[],
  sort?: SortParamType
): Promise<CountContactsResultType> {
  let path = POST_USER_PROFILE_COUNT;
  if (sort) {
    const sortQuery = new URLSearchParams({
      sortby: sort.field,
      order: sort.direction ?? "ASC",
    });
    path = `${path}?${sortQuery}`;
  }
  return await post(path, { param: filters });
}
