import Helmet from "react-helmet";
import React, { FormEvent, useCallback, useEffect, useReducer } from "react";
import { PostLogin } from "../../../component/Header";
import {
  Button,
  Dimmer,
  Input,
  Loader,
  Modal,
  Pagination,
} from "semantic-ui-react";
import GridHeader from "../../../component/shared/grid/GridHeader";
import {
  defaultListState,
  IndividualListAction,
  individualListReducer,
  IndividualListState,
} from "./IndividualList/individualListReducer";
import ContactsTable from "../../../component/Contact/ContactsTable";
import {
  isNotLabelsColumn,
  isNotListsColumn,
  useCustomProfileFields,
} from "../hooks/useCustomProfileFields";
import {
  deleteMethod,
  downloadFileViaPost,
  get,
  post,
} from "../../../api/apiRequest";
import {
  DELETE_USER_PROFILE_GROUP,
  GET_USER_PROFILE,
  POST_UPDATE_USER_PROFILE_GROUP,
  POST_UPDATE_USER_PROFILE_REMOVE_FROM_GROUP,
  POST_USER_PROFILE_EXPORT_SPREADSHEET,
} from "../../../api/apiPath";
import { useHistory, useParams } from "react-router";
import "../../../style/css/import_contacts.css";
import { UserProfileGroupType } from "./UserProfileGroupType";
import {
  SelectAllContext,
  UpdatePageEvent,
} from "../../../xstate/selectAllIItemsMachine";
import { useSelectAllBehavior } from "../../../xstate/hooks/useSelectAllBehavior";
import BulkEdit from "../../../component/Contact/BulkEdit";
import { AudienceType } from "../../../types/BroadcastCampaignType";
import { SortParamType } from "../../Contact";
import { AddToListPopupGridAction } from "../../../component/Contact/Lists/AddToListPopupGridAction";
import useImportedLists from "./useImportedLists";
import { Trans, useTranslation } from "react-i18next";
import useRouteConfig from "../../../config/useRouteConfig";
import { GridSelection } from "../../../component/shared/grid/GridSelection";
import { ExportButton, MAXIMUM_EXPORT_LIMIT } from "./ContactListsTable";
import { useAccessRulesGuard } from "../../../component/Settings/hooks/useAccessRulesGuard";
import { useFetchContactsPage } from "../../../api/Contacts/useFetchContactsPage";
import ContactSidebar from "../../../component/Contact/ContactSidebar";
import { GridControls } from "./IndividualList/GridControls";
import { EmptyListContent } from "./IndividualList/EmptyListContent";
import { useAppSelector } from "../../../AppRootContext";
import { pick } from "ramda";
import { BackNavLink } from "../../../component/shared/nav/BackNavLink";
import { HashTagCountedType } from "../../../types/ConversationType";
import { ConditionOperator } from "../hooks/ContactsStateType";
import { useContactsSearchFilter } from "../../../api/Contacts/useContactsSearchFilter";
import ModalConfirm from "../../../component/shared/ModalConfirm";

type ReducerType = React.Reducer<IndividualListState, IndividualListAction>;

export const LIMIT = 30;

function IndividualList() {
  const { id } = useParams();
  const [state, dispatch] = useReducer<ReducerType>(
    individualListReducer,
    defaultListState()
  );
  const history = useHistory();
  const { routeTo } = useRouteConfig();
  const { company, staffList } = useAppSelector(pick(["company", "staffList"]));

  const { isBooted: fieldsBooted, fields: customFields } =
    useCustomProfileFields({ excludeLabels: false });

  useEffect(() => {
    if (!fieldsBooted) {
      return;
    }
    dispatch({ type: "CUSTOM_FIELDS_LOADED" });
  }, [fieldsBooted, dispatch]);

  const { t } = useTranslation();

  const {
    itemsLoading,
    listNameValue,
    listNameChanged,
    deleteButtonLoading,
    itemsTotal, //todo remove
    itemsList,
    pagination,
    exportButtonLoading,
    listFormLoading,
    listFormSaveLoading,
  } = state;

  const { state: selectAllMachineState, send: selectAllSend } =
    useSelectAllBehavior({
      selectAllCallback: async () => {
        const result = await selectAll();
        return {
          total: result.totalResult,
          targetIds: result.userProfileIds,
        };
      },
    });

  const { buildFilters } = useContactsSearchFilter(
    state.searchFilters,
    state.tagFilters,
    state.quickSearch.query,
    [],
    state.collaboratorsFilter,
    [
      {
        filterCondition: "Contains",
        nextOperator: "And",
        fieldName: "importfrom",
        fieldType: "importfrom",
        filterValue: [id as string],
      },
    ]
  );
  const { selectAll, fetchContactsPage } = useFetchContactsPage(buildFilters);

  const { lists, listAdded, refresh } = useImportedLists();
  const accessRuleGuard = useAccessRulesGuard();
  const checkedItems = selectAllMachineState.context.targetIds;

  async function exportSelected(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (checkedItems.length === 0) {
      return;
    }
    dispatch({ type: "EXPORT_STARTED" });
    try {
      await downloadFileViaPost(
        POST_USER_PROFILE_EXPORT_SPREADSHEET,
        {
          UserProfileIds: checkedItems,
        },
        "export.csv",
        "text/csv"
      );
      dispatch({ type: "EXPORT_COMPLETED" });
    } catch (e) {
      dispatch({
        type: "EXPORT_ERROR",
        error: e,
      });
    }
  }

  async function removeContactsFromList() {
    if (checkedItems.length === 0) {
      return;
    }
    dispatch({ type: "REMOVE_CONTACTS_STARTED" });
    try {
      await post(
        POST_UPDATE_USER_PROFILE_REMOVE_FROM_GROUP.replace(
          "{id}",
          id as string
        ),
        { param: { UserProfileIds: checkedItems } }
      );
      dispatch({ type: "REMOVE_CONTACTS_COMPLETED" });
      await fetchPage(pagination.page, state.sortParams);
    } catch (error) {
      dispatch({
        type: "REMOVE_CONTACTS_ERROR",
        error,
      });
    }
  }

  const fetchPage = useCallback(
    async (
      page: number,
      sort: SortParamType[],
      tagOperator?: ConditionOperator,
      collaboratorOperator?: ConditionOperator
    ) => {
      dispatch({ type: "PAGE_LOAD_START" });
      try {
        const data = await fetchContactsPage(
          page,
          sort,
          LIMIT,
          {
            tags: state.tagFilters,
            tagOperator: tagOperator ? tagOperator : state.tagOperator,
            collaboratorIds: state.collaboratorsFilter,
            collaboratorOperator: collaboratorOperator,
            quickSearch: state.quickSearch.query.trim() || undefined,
          },
          selectAllMachineState.matches("selectedAll")
        );

        dispatch({
          type: "PAGE_LOADED_OK",
          data: data.items,
          page: page,
          total: data.totalFiltered,
          totalUnfiltered: data.totalUnfiltered,
        });
        const event: UpdatePageEvent = {
          type: "UPDATE_SELECTION",
          pageIds: data.items.map((up) => up.id),
          total: data.totalFiltered,
          totalUnfiltered: data.totalUnfiltered,
          targetIds:
            data.selectedIds ?? selectAllMachineState.context.targetIds,
          pageSize: LIMIT,
          pageNumber: page,
          pagesCount: Math.ceil(data.totalFiltered / LIMIT),
        };
        selectAllSend(event);
      } catch (error) {
        dispatch({ type: "PAGE_LOADED_ERROR", error });
      }
    },
    [fetchContactsPage, state.quickSearch.query]
  );

  async function deleteList() {
    dispatch({ type: "DELETE_LIST_STARTED" });
    try {
      await deleteMethod(DELETE_USER_PROFILE_GROUP, {
        param: {
          ListIds: [id],
        },
      });
      dispatch({ type: "DELETE_LIST_COMPLETED" });
      history.replace({
        pathname: routeTo("/contacts/lists"),
        state: {
          flashMessage: {
            text: t("flash.lists.deleted", { name: state.listNamePersisted }),
            timeout: 2000,
          },
        },
      });
    } catch (error) {
      dispatch({
        type: "DELETE_LIST_ERROR",
        error,
      });
    }
  }

  function setListName(value: string) {
    dispatch({
      type: "SET_LIST_NAME",
      name: value,
    });
  }

  useEffect(() => {
    fetchListData();
  }, [id]);

  const fetchListData = useCallback(async () => {
    dispatch({ type: "LIST_LOAD_STARTED" });
    try {
      const result: UserProfileGroupType = await get(
        GET_USER_PROFILE.replace("{id}", id as string),
        { param: {} }
      );
      if (result) {
        const totalResult = result.totalContactCount;
        dispatch({
          type: "LIST_LOAD_COMPLETED",
          name: result.importName,
          totalCount: totalResult,
        });
        return {
          totalCount: totalResult,
        };
      } else {
        dispatch({
          type: "UPDATE_SHOW_ERROR_MODAL",
          show: true,
        });
      }
    } catch (error) {
      console.error("error: ", error);
      dispatch({
        type: "LIST_LOAD_ERROR",
        error,
      });

      return {
        totalCount: 0,
      };
    }
  }, [id]);

  async function submitListData() {
    dispatch({ type: "LIST_PERSIST_STARTED" });
    try {
      const result: UserProfileGroupType = await post(
        POST_UPDATE_USER_PROFILE_GROUP.replace("{id}", id as string),
        {
          param: { GroupListName: listNameValue },
        }
      );
      refresh();
      dispatch({
        type: "LIST_PERSIST_COMPLETED",
        name: result.importName,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: "LIST_PERSIST_ERROR" });
    }
  }

  async function handleSort(param: SortParamType) {
    dispatch({ type: "SEARCH_SORT_UPDATE", params: [param] });
  }

  useEffect(() => {
    if (id === undefined) {
      history.replace(routeTo("/contacts/lists"));
    }
  }, [id === undefined]);

  let formCanBeSubmitted =
    listNameChanged &&
    listNameValue.trim() !== "" &&
    !(listFormLoading || listFormSaveLoading);

  const selectAllIds = async () => {
    if (selectAllMachineState.matches("selectedAll")) {
      const result = await selectAll();
      return result.userProfileIds;
    } else {
      return (selectAllMachineState!.context! as SelectAllContext).targetIds;
    }
  };

  function goToCampaign() {
    history.push({
      pathname: routeTo(`/campaigns/create`),
      state: {
        contacts: id,
      },
    });
  }

  const pageTitle = t("nav.menu.lists");

  function updateFilterList(filters: AudienceType[]) {
    dispatch({ type: "SEARCH_FILTERS_UPDATE", filters });
  }

  const loading = itemsLoading || itemsList === undefined;

  const setCollaboratorFilters = (ids: string[]) => {
    dispatch({
      type: "COLLABORATORS_FILTER_UPDATED",
      values: ids,
      operator: state.collaboratorOperator,
    });
  };

  const setCollaboratorAndOperatorFilter = (
    ids: string[],
    operator: ConditionOperator
  ) => {
    dispatch({
      type: "COLLABORATORS_FILTER_UPDATED",
      values: ids,
      operator,
    });
    fetchPage(1, state.sortParams, state.tagOperator, operator);
  };

  useEffect(() => {
    fetchPage(state.pagination.page, state.sortParams);
  }, [fetchPage, state.pagination.page, JSON.stringify(state.sortParams)]);

  const anyFilterApplied = state.itemsTotalUnfiltered > state.itemsTotal;

  const setTagAndOperatorFilter = useCallback(
    (tags: HashTagCountedType[], operator: ConditionOperator) => {
      dispatch({
        type: "TAG_FILTERS_UPDATED",
        tagOperator: operator,
        tags: tags,
      });
      fetchPage(1, state.sortParams, operator, state.collaboratorOperator);
    },
    [fetchPage]
  );

  const handleNotFoundClick = () => {
    history.push(routeTo("/contacts/lists"));
  };
  return (
    <>
      <div className="post-login">
        <Helmet title={t("nav.common.title", { page: pageTitle })} />
        <PostLogin selectedItem={"Contacts"} />
        <div className="main imported-contacts">
          <Dimmer active={loading} inverted>
            <Loader active={true} />
          </Dimmer>
          <div className="navigation">
            <BackNavLink to={routeTo("/contacts/lists")}>
              {t("nav.back", { to: t("nav.menu.lists") })}
            </BackNavLink>
          </div>
          <div className="main-primary-column">
            <GridHeader
              actionsPosition={"top"}
              deleteConfirmationRequested={state.deleteContactsRequested}
              requestDeleteConfirmation={(show) => {
                dispatch({
                  type: "REMOVE_CONTACTS_CONFIRMATION",
                  visible: show,
                });
              }}
              title={
                <div className="individual-list-form ui form">
                  <div className="form-title-counted">
                    <h1>{t("profile.lists.individual.title")}</h1>
                    <div className="stats">
                      {t("profile.contacts.sidebar.header.counter", {
                        count: anyFilterApplied
                          ? state.itemsTotal
                          : state.itemsTotalUnfiltered,
                      })}
                    </div>
                  </div>
                  <div className="field">
                    <Input
                      type="text"
                      placeholder={t("form.list.field.name.placeholder")}
                      value={listNameValue}
                      disabled={listFormLoading || listFormSaveLoading}
                      onInput={(event: FormEvent<HTMLInputElement>) =>
                        setListName(event.currentTarget.value)
                      }
                    />
                  </div>
                </div>
              }
              deleteLoading={deleteButtonLoading}
              onDeleteClick={removeContactsFromList}
              selectedItemsCount={selectAllMachineState.context.targetIdsCount}
              deleteLabel={t("profile.list.grid.header.deleteLabel")}
              deleteTooltip={t("profile.tooltip.list.action.delete")}
              batchButtons={
                <>
                  <AddToListPopupGridAction
                    selectedContactsCount={
                      selectAllMachineState.context.targetIdsCount
                    }
                    importedLists={lists.filter(
                      (list) => list.id !== Number(id)
                    )}
                    selectAllIds={selectAllIds}
                    appendList={listAdded}
                  />
                  <BulkEdit
                    selectedCount={selectAllMachineState.context.targetIdsCount}
                    setProfiles={(data) => [
                      dispatch({ type: "PAGE_REPAINT", data: data }),
                    ]}
                    customFields={customFields.filter(
                      (f) => isNotLabelsColumn(f) || isNotListsColumn(f)
                    )}
                    profiles={state.itemsList ?? []}
                    staffList={staffList ?? []}
                    companyCountry={company?.companyCountry ?? ""}
                    companyTags={company?.companyHashtags ?? []}
                    selectAllIds={selectAllIds}
                  />
                  <ExportButton
                    isAllowedToExport={
                      accessRuleGuard.canExportContacts() &&
                      checkedItems.length <= MAXIMUM_EXPORT_LIMIT
                    }
                    onClick={exportButtonLoading ? undefined : exportSelected}
                    loading={exportButtonLoading}
                  />
                </>
              }
              afterMainRow={
                <GridControls
                  dispatch={dispatch}
                  state={state}
                  setTagAndOperatorFilter={setTagAndOperatorFilter}
                />
              }
            >
              <>
                <Button
                  onClick={() => dispatch({ type: "DELETE_LIST_ATTEMPT" })}
                  loading={exportButtonLoading}
                  className={"button-small"}
                >
                  {t("profile.lists.button.delete")}
                </Button>
                <Button
                  primary
                  className={"button-small"}
                  onClick={goToCampaign}
                >
                  {t("broadcast.grid.button.create")}
                </Button>
                <Button
                  primary
                  disabled={!formCanBeSubmitted}
                  loading={listFormSaveLoading}
                  className={"button-small"}
                  onClick={
                    !(listFormLoading || listFormSaveLoading)
                      ? () => submitListData()
                      : undefined
                  }
                >
                  {t("form.button.save")}
                </Button>
              </>
            </GridHeader>
            <ContactSidebar
              existingFilters={state.searchFilters}
              numOfContacts={state.itemsTotal}
              numOfContactsTotal={state.itemsTotalUnfiltered}
              visibleSidebar={true}
              fields={customFields.filter(
                (f) => isNotLabelsColumn(f) && isNotListsColumn(f)
              )}
              isIncludeCollaborator={true}
              setFilterValues={updateFilterList}
              isDisplayWalkThroughTooltip={false}
              setIsDisplayWalkThroughTooltip={() => null}
              visible={state.filtersVisible}
              onHide={() => {
                dispatch({ type: "FILTERS_TOGGLE", visible: false });
              }}
              tagFilters={state.tagFilters}
              setTagFilters={(filters) => {
                dispatch({
                  type: "TAG_FILTERS_UPDATED",
                  tags: filters,
                  tagOperator: "And",
                });
              }}
              tagOperator={state.tagOperator}
              setTagAndOperatorFilter={setTagAndOperatorFilter}
              collaboratorFilters={state.collaboratorsFilter}
              collaboratorOperator={state.collaboratorOperator}
              setCollaboratorFilters={setCollaboratorFilters}
              setCollaboratorAndOperatorFilter={
                setCollaboratorAndOperatorFilter
              }
            />
            <section className="contact-list hide-scrollable-table">
              <div className="stick-wrap">
                {!itemsLoading && itemsList && (
                  <ContactsTable
                    loading={itemsLoading}
                    customFields={customFields}
                    profileResult={itemsList}
                    selectAllState={selectAllMachineState}
                    selectAllUpdate={selectAllSend}
                    sortBy={handleSort}
                    sort={state.sortParams}
                    emptyContent={<EmptyListContent />}
                    contextActions={
                      <GridSelection
                        selectedItemsCount={
                          selectAllMachineState.context.targetIdsCount
                        }
                        itemsSingular={t("profile.contacts.pluralize.single")}
                        itemsPlural={t("profile.contacts.pluralize.multiple")}
                        deletePrompt={t(
                          "profile.list.grid.header.deletePrompt"
                        )}
                        deleteConfirmationRequested={
                          state.deleteContactsRequested
                        }
                      />
                    }
                  />
                )}
              </div>
            </section>
            {pagination.pagesCount > 1 && (
              <footer className="footer">
                <Pagination
                  defaultActivePage={pagination.page}
                  activePage={pagination.page}
                  onPageChange={(_, data) =>
                    dispatch({
                      type: "PAGE_CHANGED",
                      page: data.activePage as number,
                    })
                  }
                  totalPages={pagination.pagesCount}
                />
              </footer>
            )}
          </div>
        </div>
      </div>
      <ConfirmDelete
        dispatch={dispatch}
        show={state.showDeleteListConfirmation}
        loading={state.deleteListLoading}
        onConfirm={async () => {
          deleteList();
        }}
      />
      <ModalConfirm
        opened={state.showErrorModal}
        onConfirm={handleNotFoundClick}
        title={t("warning")}
        confirmText={t("form.button.close")}
      >
        {t("profile.warning.notFound")}
      </ModalConfirm>
    </>
  );
}

function ConfirmDelete(props: {
  show: boolean;
  loading: boolean;
  onConfirm: Function;
  dispatch: React.Dispatch<IndividualListAction>;
}) {
  const { show, dispatch, loading, onConfirm } = props;
  const { t } = useTranslation();

  function onClose() {
    dispatch({ type: "DELETE_LIST_CANCEL" });
  }

  return (
    <Modal
      open={show}
      closeOnDimmerClick={false}
      dimmer={"inverted"}
      className={"modal-confirm"}
      size={"small"}
      onClose={onClose}
    >
      <Modal.Header>{t("profile.list.confirmDelete.header")}</Modal.Header>
      <Modal.Content>
        <Trans i18nKey={"profile.list.confirmDelete.text"}>
          <p>
            Delete this list? This action can't be undone. The contacts in this
            list won't be deleted.
          </p>
        </Trans>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={!loading ? () => onConfirm() : undefined}
          loading={loading}
        >
          {t("form.button.delete")}
        </Button>
        <Button onClick={!loading ? onClose : undefined} disabled={loading}>
          {t("form.button.cancel")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default IndividualList;
