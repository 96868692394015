import React from "react";
import { Image } from "semantic-ui-react";
import { URL } from "../../api/apiRequest";
import Avatar from "react-avatar";
import { GET_COMPANY_STAFF_PIC } from "../../api/apiPath";
import styles from "./UserProfileDropdown.module.css";

export default function AccountPic(props: {
  userName: string;
  profilePic: string;
}) {
  const { userName, profilePic } = props;
  return (
    <>
      {userName &&
        (profilePic ? (
          <Image
            size={"tiny"}
            avatar
            src={`${URL}${GET_COMPANY_STAFF_PIC}/${profilePic}`}
          />
        ) : (
          <div className={styles.avatarContainer}>
            <Avatar
              name={userName}
              maxInitials={2}
              round={true}
              className={styles.avatar}
            />
          </div>
        ))}
    </>
  );
}
