import {
  NormalizedSearchResponseType,
  sortFieldDirectionMap,
  sortFieldNameMap,
  SortParamType,
} from "../../container/Contact";
import {
  CountContactsResultType,
  SearchContactResultType,
  SelectAllContactResultType,
} from "../../types/ProfileSearchType";
import { useCallback } from "react";
import { fetchSelectAll } from "./fetchSelectAll";
import { fetchCountContacts } from "./fetchCountContacts";
import { fetchSearchContacts } from "./fetchSearchContacts";
import { fetchAllContacts } from "./fetchAllContacts";
import {
  ContactsRequestExtensionType,
  FiltersTupleType,
} from "./useContactsSearchFilter";

export interface ContactsRequestPageExtensionType
  extends ContactsRequestExtensionType {
  sort?: SortParamType[];
  page?: number;
}

export function useFetchContactsPage(
  buildFilters: (extend: ContactsRequestExtensionType) => FiltersTupleType,
  quickSearch?: string
) {
  const fetchContactsPage = useCallback(
    async (
      page: number,
      sort: SortParamType[],
      pageSize: number,
      extend: ContactsRequestExtensionType,
      allSelected: boolean
    ): Promise<NormalizedSearchResponseType> => {
      let query: Parameters<typeof fetchAllContacts>[0] = {
        limit: String(pageSize),
        offset: String(pageSize * (page - 1)),
      };
      const [onlySortParam] = sort;
      if (onlySortParam?.direction) {
        query.sortby =
          sortFieldNameMap[onlySortParam.field] ?? onlySortParam.field;
        query.order =
          sortFieldDirectionMap[onlySortParam.direction] ??
          onlySortParam.direction;
      }
      const [adjustableFilters, constantFilters] = buildFilters(extend);
      const allFilters =
        quickSearch?.trim() !== ""
          ? [...adjustableFilters, ...constantFilters]
          : [...constantFilters, ...adjustableFilters];

      let searchPromise: Promise<SearchContactResultType>;
      if (allFilters.length > 0) {
        searchPromise = fetchSearchContacts(query, allFilters);
      } else {
        searchPromise = fetchAllContacts(query);
      }

      let searchResult: SearchContactResultType;
      let totalResultFiltered: SelectAllContactResultType | undefined =
        undefined;
      try {
        [searchResult, totalResultFiltered] = await Promise.all(
          allSelected
            ? [searchPromise, fetchSelectAll(allFilters)]
            : [searchPromise, undefined]
        );
        const userProfileIds = searchResult.userProfiles.map((up) => up.id);

        return {
          items: searchResult.userProfiles,
          totalFiltered: allSelected
            ? totalResultFiltered?.totalResult ?? 0
            : searchResult.totalResult,
          totalUnfiltered: allSelected
            ? totalResultFiltered?.totalResult ?? 0
            : searchResult.totalResult,
          pageIds: userProfileIds,
          targetIdsCount: allSelected
            ? totalResultFiltered?.totalResult ?? 0
            : searchResult.totalResult,
          selectedIds: allSelected
            ? totalResultFiltered?.userProfileIds
            : undefined,
        };
      } catch (error) {
        console.error(`Contact: fetch user profile error ${error}`);
        return {
          items: [],
          totalFiltered: 0,
          totalUnfiltered: 0,
          pageIds: [],
          targetIdsCount: 0,
          selectedIds: undefined,
        };
      }
    },
    [buildFilters]
  );

  function buildContactsCountPromise(
    extend: ContactsRequestExtensionType = {},
    sort: SortParamType,
    allSelected: boolean
  ): Promise<[SelectAllContactResultType, SelectAllContactResultType]> {
    const [adjustableFilters, constantFilters] = buildFilters(extend);
    const allFilters = [...constantFilters, ...adjustableFilters];

    function transformToSelectAllResult(r: CountContactsResultType) {
      return {
        totalResult: r.totalResult,
        allProfilesSelectedCount: 0,
        userProfileIds: [],
      };
    }

    const countUnfilteredPromise = fetchCountContacts(
      constantFilters,
      sort
    ).then(transformToSelectAllResult);
    if (!allSelected) {
      const countFilteredPromise = fetchCountContacts(allFilters, sort).then(
        transformToSelectAllResult
      );
      if (adjustableFilters.length > 0) {
        return Promise.all([countFilteredPromise, countUnfilteredPromise]);
      } else {
        return countFilteredPromise.then((result) => [result, result]);
      }
    } else {
      const selectFilteredPromise = fetchSelectAll(allFilters);
      if (adjustableFilters.length > 0) {
        return Promise.all([selectFilteredPromise, countUnfilteredPromise]);
      } else {
        return selectFilteredPromise.then((result) => [result, result]);
      }
    }
  }

  const selectAll = useCallback(
    async (
      extend: ContactsRequestExtensionType = {}
    ): Promise<SelectAllContactResultType> => {
      const [adjustableFilters, constantFilters] = buildFilters(extend);
      const allFilters = [...adjustableFilters, ...constantFilters];
      return fetchSelectAll(allFilters);
    },
    [buildFilters]
  );

  return {
    fetchContactsPage,
    selectAll,
  };
}
