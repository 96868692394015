import {
  COUNTRY_CODE_FETCH_API,
  getExternalResource,
  getWithExceptions,
} from "./apiRequest";

export interface IpResponseType {
  ipAddress: string;
}

export const getCountryCode = async () => {
  const publicIPResult = (await getWithExceptions("/ipaddress", {
    param: {},
    config: {
      skipAuth: true,
    },
  })) as IpResponseType;
  const publicIp = publicIPResult.ipAddress;
  const result = (await getExternalResource(
    COUNTRY_CODE_FETCH_API.replace("{publicIP}", publicIp),
    { param: { skipAuth: true } }
  )) as
    | {
        asn: string;
        asnName: string;
        asnOrg: string;
        businessName: string;
        businessWebsite: string;
        city: string;
        continent: string;
        country: string;
        countryCode: string;
        ipName: string;
        ipType: string;
        isp: string;
        lat: string;
        lon: string;
        org: string;
        query: string;
        region: string;
        status: string;
        timezone: string;
        utcOffset: string;
      }
    | {
        status: string;
        message: string;
      };
  return result;
};
