import AssignmentResponseType from "../../types/AssignmentRuleType";
import { GET_ASSIGNMENT_RULES } from "../apiPath";
import { get } from "../apiRequest";

export default async function fetchAutomationRules() {
  const assignmentRulesResult: AssignmentResponseType[] = await get(
    GET_ASSIGNMENT_RULES,
    {
      param: {
        offset: 0,
        limit: 530,
      },
    }
  );
  return assignmentRulesResult;
}
