import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";
import { ContactListType } from "../../../types/ContactType";
import ContactListRow from "./../Lists/ContactListRow";

const ContactListContainer = (props: {
  contactLists: ContactListType[] | undefined;
}) => {
  const { contactLists = [] } = props;
  const { t } = useTranslation();

  return (
    <>
      {contactLists.length > 0 && (
        <div className="container">
          <Header as={"h4"}>{t("chat.lists.header")}</Header>
          <div>
            {contactLists.map((tag, index: number) => (
              <ContactListRow tag={tag} key={index} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default ContactListContainer;
