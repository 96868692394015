import React from "react";

function CrossLgIcon() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a.999.999 0 01-1.72-.703 1 1 0 01.306-.711L8.586 10 3.293 4.707a1 1 0 011.414-1.414L10 8.586l5.293-5.293a1 1 0 011.414 1.414L11.414 10z"
        fill="#3C4257"
      />
    </svg>
  );
}

export default CrossLgIcon;
