import React from "react";
import produce from "immer";
import ProfileSearchType from "../../../../types/ProfileSearchType";
import { ListPagination } from "../reducer";
import { AudienceType } from "../../../../types/BroadcastCampaignType";
import { SortParamType } from "../../../Contact";
import { HashTagCountedType } from "../../../../types/ConversationType";
import { LIMIT } from "../IndividualList";
import {
  ConditionOperator,
  DefaultOperatorValue,
} from "../../hooks/ContactsStateType";

export type IndividualListAction =
  | { type: "CUSTOM_FIELDS_LOADED" }
  | { type: "SET_LIST_NAME"; name: string }
  | { type: "PAGE_CHANGED"; page: number }
  | { type: "PAGE_LOAD_START" }
  | {
      totalUnfiltered: number;
      type: "PAGE_LOADED_OK";
      data: ProfileSearchType[];
      page: number;
      total: number;
    }
  | { type: "PAGE_LOADED_ERROR"; error: string }
  | { type: "PAGE_REPAINT"; data: ProfileSearchType[] }
  | { type: "EXPORT_STARTED" }
  | { type: "EXPORT_COMPLETED" }
  | { type: "EXPORT_ERROR"; error: string }
  | { type: "DELETE_LIST_ATTEMPT" }
  | { type: "DELETE_LIST_CANCEL" }
  | { type: "DELETE_LIST_STARTED" }
  | { type: "DELETE_LIST_COMPLETED" }
  | { type: "DELETE_LIST_ERROR"; error: string }
  | { type: "REMOVE_CONTACTS_CONFIRMATION"; visible: boolean }
  | { type: "REMOVE_CONTACTS_STARTED" }
  | { type: "REMOVE_CONTACTS_COMPLETED" }
  | { type: "REMOVE_CONTACTS_ERROR"; error: string }
  | { type: "LIST_LOAD_STARTED" }
  | { type: "LIST_LOAD_COMPLETED"; name: string; totalCount: number }
  | { type: "LIST_LOAD_ERROR"; error: string }
  | { type: "LIST_PERSIST_STARTED" }
  | { type: "LIST_PERSIST_COMPLETED"; name: string }
  | { type: "LIST_PERSIST_ERROR" }
  | { type: "SEARCH_FILTERS_UPDATE"; filters: AudienceType[] }
  | { type: "SEARCH_SORT_UPDATE"; params: SortParamType[] }
  | { type: "QUICK_SEARCH_UPDATE"; search: string }
  | { type: "QUICK_SEARCH_EXECUTE" }
  | {
      type: "TAG_FILTERS_UPDATED";
      tags: HashTagCountedType[];
      tagOperator: ConditionOperator;
    }
  | {
      type: "COLLABORATORS_FILTER_UPDATED";
      values: string[];
      operator: ConditionOperator;
    }
  | { type: "FILTERS_TOGGLE"; visible: boolean }
  | { type: "FILTERS_RESET" }
  | { type: "UPDATE_SHOW_ERROR_MODAL"; show: boolean };

export interface IndividualListState {
  showDeleteListConfirmation: boolean;
  listNamePersisted: string;
  listNameValue: string;
  listNameChanged: boolean;
  pagination: ListPagination;
  itemsList: ProfileSearchType[] | undefined;
  itemsTotal: number;
  itemsTotalUnfiltered: number;
  deleteListLoading: boolean;
  itemsLoading: boolean;
  deleteContactsRequested: boolean;
  deleteButtonLoading: boolean;
  exportButtonLoading: boolean;
  listFormLoading: boolean;
  listFormSaveLoading: boolean;
  searchFilters: AudienceType[];
  sortParams: SortParamType[];
  tagFilters: HashTagCountedType[];
  quickSearch: { query: string; queryInput: string };
  filtersVisible: boolean;
  tagOperator: ConditionOperator;
  collaboratorsFilter: string[];
  collaboratorOperator: ConditionOperator;
  showErrorModal: boolean;
}

export const individualListReducer = produce(
  (draft: IndividualListState, action: IndividualListAction) => {
    switch (action.type) {
      case "SET_LIST_NAME":
        draft.listNameValue = action.name;
        draft.listNameChanged = draft.listNameValue !== draft.listNamePersisted;
        break;

      case "CUSTOM_FIELDS_LOADED":
        draft.itemsLoading = false;
        break;

      case "PAGE_CHANGED":
        draft.pagination.page = action.page;
        break;

      case "PAGE_LOAD_START":
        draft.itemsLoading = true;
        break;

      case "PAGE_REPAINT":
        draft.itemsList = action.data;
        break;

      case "PAGE_LOADED_OK":
        draft.itemsList = action.data;
        draft.pagination.page = action.page;
        draft.pagination.pagesCount = Math.ceil(action.total / LIMIT);
        draft.itemsTotal = action.total;
        draft.itemsTotalUnfiltered = action.totalUnfiltered;
        draft.itemsLoading = false;
        break;

      case "PAGE_LOADED_ERROR":
        console.error(action.type, action.error);
        draft.itemsLoading = false;
        break;

      case "EXPORT_STARTED":
        draft.exportButtonLoading = true;
        break;

      case "EXPORT_ERROR":
        console.error(action.type, action.error);
        draft.exportButtonLoading = false;
        break;

      case "EXPORT_COMPLETED":
        draft.exportButtonLoading = false;
        break;

      case "DELETE_LIST_ATTEMPT":
        draft.showDeleteListConfirmation = true;
        break;

      case "DELETE_LIST_CANCEL":
        draft.showDeleteListConfirmation = false;
        break;

      case "DELETE_LIST_STARTED":
        draft.deleteListLoading = true;
        break;

      case "DELETE_LIST_COMPLETED":
        draft.itemsLoading = false;
        break;

      case "DELETE_LIST_ERROR":
        console.error(action.type, action.error);
        draft.itemsLoading = false;
        break;

      case "REMOVE_CONTACTS_CONFIRMATION":
        draft.deleteContactsRequested = action.visible;
        break;

      case "REMOVE_CONTACTS_STARTED":
        draft.deleteButtonLoading = true;
        break;

      case "REMOVE_CONTACTS_COMPLETED":
        draft.deleteButtonLoading = false;
        break;

      case "REMOVE_CONTACTS_ERROR":
        console.error(action.type, action.error);
        draft.deleteButtonLoading = false;
        break;

      case "LIST_LOAD_STARTED":
        draft.listFormLoading = true;
        break;

      case "LIST_LOAD_COMPLETED":
        draft.listNameChanged = false;
        draft.listNamePersisted = action.name;
        draft.listNameValue = action.name;
        draft.listFormLoading = false;
        draft.itemsTotal = action.totalCount;
        break;

      case "LIST_LOAD_ERROR":
        console.error(action.type, action.error);
        draft.listFormLoading = false;
        break;

      case "LIST_PERSIST_STARTED":
        draft.listFormSaveLoading = true;
        break;

      case "LIST_PERSIST_COMPLETED":
        draft.listNamePersisted = action.name;
        draft.listNameChanged = false;
        draft.listFormSaveLoading = false;
        break;

      case "LIST_PERSIST_ERROR":
        draft.listFormSaveLoading = false;
        break;

      case "SEARCH_FILTERS_UPDATE":
        draft.searchFilters = action.filters;
        draft.quickSearch.query = draft.quickSearch.queryInput;
        draft.pagination.page = 1;
        break;

      case "SEARCH_SORT_UPDATE":
        draft.sortParams = action.params;
        break;

      case "QUICK_SEARCH_UPDATE":
        draft.quickSearch.queryInput = action.search;
        break;

      case "QUICK_SEARCH_EXECUTE":
        draft.quickSearch.query = draft.quickSearch.queryInput;
        break;

      case "TAG_FILTERS_UPDATED":
        draft.tagFilters = action.tags;
        draft.quickSearch.query = draft.quickSearch.queryInput;
        draft.tagOperator = action.tagOperator;
        break;

      case "COLLABORATORS_FILTER_UPDATED":
        draft.quickSearch.query = draft.quickSearch.queryInput;
        draft.collaboratorsFilter = action.values;
        draft.collaboratorOperator = action.operator;
        break;

      case "FILTERS_TOGGLE":
        draft.filtersVisible = action.visible;
        break;

      case "FILTERS_RESET":
        draft.searchFilters = [];
        draft.tagFilters = [];
        draft.collaboratorsFilter = [];
        draft.pagination.page = 1;
        break;

      case "UPDATE_SHOW_ERROR_MODAL":
        draft.showErrorModal = action.show;
        break;
    }
  }
);

export function defaultListState(): IndividualListState {
  return {
    showDeleteListConfirmation: false,
    listNameChanged: false,
    listNameValue: "",
    listNamePersisted: "",
    deleteButtonLoading: false,
    deleteContactsRequested: false,
    exportButtonLoading: false,
    deleteListLoading: false,
    listFormLoading: true,
    listFormSaveLoading: false,
    itemsList: undefined,
    pagination: {
      page: 1,
      pagesCount: 1,
      itemsCount: 0,
    },
    itemsTotal: 0,
    itemsTotalUnfiltered: 0,
    itemsLoading: true,
    searchFilters: [],
    sortParams: [],
    tagFilters: [],
    collaboratorsFilter: [],
    collaboratorOperator: DefaultOperatorValue,
    filtersVisible: false,
    tagOperator: "Or",
    quickSearch: { query: "", queryInput: "" },
    showErrorModal: false,
  };
}
