import { CustomProfileField } from "../../../types/ContactType";
import React from "react";
import ProfileSearchType from "../../../types/ProfileSearchType";
import { SortParamType } from "../../Contact";
import { AudienceType } from "../../../types/FilterConfigType";
import { HashTagCountedType } from "../../../types/ConversationType";
import { isListFilter } from "../../ContactMain";

export type ContactsStateType = {
  booted: boolean;
  numOfContacts: number;
  numOfContactsUnfiltered: number;
  fields: CustomProfileField[];
  activePage: number;
  totalPages: number;
  deleteLoading: boolean;
  deleteConfirmationVisible: boolean;
  profileResult: ProfileSearchType[];
  loading: boolean;
  filterDrawerVisible: boolean;
  filters: AudienceType[];
  sortParams: SortParamType[];
  newContactFormVisible: boolean;
  tagFilters: HashTagCountedType[];
  tagOperator: ConditionOperator;
  listIdFilters: string[];
  listOperator: ConditionOperator;
  collaboratorFilters: string[];
  collaboratorOperator: ConditionOperator;
  setTagAndOperatorFilter?: (
    tags: HashTagCountedType[],
    operator: ConditionOperator
  ) => void;
  setListIdAndOperatorFilter?: (
    ids: string[],
    operator: ConditionOperator
  ) => void;
  errorModal: {
    visible: boolean;
  };
  quickFilter: { query: string };
};

export type ContactActionType =
  | { type: "UPDATE_CONTACTS_NUMBER"; number: number }
  | { type: "UPDATE_FIELDS"; fields: CustomProfileField[] }
  | { type: "UPDATE_PROFILES"; profiles: ProfileSearchType[] }
  | { type: "QUICK_FILTER.UPDATE"; value: string }
  | { type: "FILTERS.TOGGLE_DRAWER"; visible: boolean }
  | { type: "SHOW_NEW_CONTACT_FORM" }
  | { type: "HIDE_NEW_CONTACT_FORM" }
  | { type: "PAGE_LOAD_START" }
  | { type: "PAGE_LOAD_STOP" }
  | {
      type: "PAGE_LOAD_END";
      profiles: ProfileSearchType[];
      pagesTotal: number;
      itemsTotal: number;
      itemsTotalUnfiltered: number;
      page: number;
    }
  | { type: "CONTACT_DELETE_START" }
  | { type: "CONTACT_DELETE_CONFIRMATION"; visible: boolean }
  | { type: "CONTACT_DELETE_END" }
  | {
      type: "UPDATE_QUERY";
      filters?: AudienceType[];
      page?: number;
      sort?: SortParamType[];
      tags?: HashTagCountedType[];
      tagOperator?: ConditionOperator;
      listIds?: string[];
      listOperator?: ConditionOperator;
      collaboratorIds?: string[];
      collaboratorOperator?: ConditionOperator;
    }
  | { type: "RESET_QUERY" }
  | { type: "SHOW_ERROR_MESSAGE_MODAL" }
  | { type: "HIDE_ERROR_MESSAGE_MODAL" };

export const DefaultOperatorValue = "Or";
export function getDefaultStateValue(): ContactsStateType {
  return {
    booted: false,
    activePage: 1,
    deleteLoading: false,
    deleteConfirmationVisible: false,
    fields: [],
    filters: [],
    filterDrawerVisible: false,
    loading: true,
    newContactFormVisible: false,
    numOfContacts: 0,
    numOfContactsUnfiltered: 0,
    profileResult: [],
    sortParams: [],
    totalPages: 0,
    tagFilters: [],
    tagOperator: DefaultOperatorValue,
    listIdFilters: [],
    listOperator: DefaultOperatorValue,
    collaboratorFilters: [],
    collaboratorOperator: DefaultOperatorValue,
    quickFilter: { query: "" },
    setTagAndOperatorFilter: () => {},
    setListIdAndOperatorFilter: () => {},
    errorModal: {
      visible: false,
    },
  };
}

export const ContactsContext = React.createContext<ContactsStateType>(
  getDefaultStateValue()
);

export function selectListsFilterValues(state: ContactsStateType) {
  return state.filters.filter(isListFilter).map((f) => f.filterValue[0]);
}

export type ConditionOperator = "And" | "Or";
export const ConditionOperatorMapping = {
  And: "ContainsAll",
  Or: "ContainsAny",
};
