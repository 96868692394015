import { post } from "../apiRequest";
import {
  SendInteractiveMessageType,
  SendInteractiveMessageWithContentType,
} from "../../types/SendMessageType";

export async function submitSendMessage(
  param: SendInteractiveMessageType | SendInteractiveMessageWithContentType
) {
  return await post("/ConversationMessages/SendMessage", {
    param: param,
    header: {},
  });
}
