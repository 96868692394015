import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Dropdown, Header, Input, Menu } from "semantic-ui-react";
import AvailableFilterList from "../FilterList/FilterList";
import EditFilterCondition from "../FilterList/FilterCondition";
import FilterSelectedItem, {
  FilterSelectedItemView,
} from "../FilterList/FilterSelectedItem";
import { CustomProfileField } from "../../types/ContactType";
import { AudienceType } from "../../types/BroadcastCampaignType";
import produce from "immer";
import { useFieldLocales } from "./locaizable/useFieldLocales";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "../shared/popup/InfoTooltip";
import { FilterConfigType } from "../../types/FilterConfigType";
import { FILTERS_STORAGE_KEY } from "../../container/Contact";
import { complement, equals } from "ramda";
import { visibleInGridHeader } from "../../container/ContactMain";
import { Drawer } from "../shared/sidebar/Drawer";
import { useCompanyHashTags } from "../Settings/hooks/useCompanyHashTags";
import { HashTagCountedType } from "../../types/ConversationType";
import { ChatLabel } from "../Chat/ChatLabel";
import { TagInput } from "../shared/input/TagInput";
import useImportedLists from "../../container/Contact/Imported/useImportedLists";
import { getQueryMatcher } from "../../container/Settings/filters/getQueryMatcher";
import { ConditionCheckboxBlock } from "../shared/condition/ConditionCheckboxBlock";
import {
  ConditionOperator,
  ContactsContext,
  DefaultOperatorValue,
} from "../../container/Contact/hooks/ContactsStateType";
import { AssignmentPopup } from "component/Chat/AssignmentPopup";
import {
  TeamCollapsible,
  useCollapsibleTeams,
} from "component/Chat/TeamCollapsible";
import { StaffItemAvatar } from "component/Chat/StaffItemAvatar";
import { staffDisplayName } from "component/Chat/utils/staffDisplayName";
import { useTeams } from "container/Settings/useTeams";
import { getStaffId } from "types/TeamType";
import { StaffType } from "types/StaffType";
import styles from "./ContactSidebar.module.css";
import { CollaboratorItem } from "component/Chat/ChatHeader/CollaboratorsInput";
import { useAppSelector } from "AppRootContext";

import { BackLink } from "../shared/nav/BackLink";

function ContactSidebar(props: {
  visible: boolean;
  numOfContacts: number;
  numOfContactsTotal: number;
  visibleSidebar: boolean;
  fields: CustomProfileField[];
  setFilterValues: (filters: AudienceType[]) => void;
  existingFilters: AudienceType[];
  tagFilters: HashTagCountedType[];
  setTagFilters: (tags: HashTagCountedType[]) => void;
  listIdFilters?: string[];
  setListIdFilters?: (ids: string[]) => void;
  collaboratorFilters?: string[];
  collaboratorOperator: ConditionOperator;
  setCollaboratorFilters?: (ids: any) => void;
  setCollaboratorAndOperatorFilter?: (
    ids: string[],
    operator: ConditionOperator
  ) => void;
  isDisplayWalkThroughTooltip: boolean;
  setIsDisplayWalkThroughTooltip: (displayPopup: boolean) => void;
  onHide: () => void;
  setTagAndOperatorFilter?: (
    tags: HashTagCountedType[],
    operator: ConditionOperator
  ) => void;
  tagOperator: ConditionOperator;
  isIncludeCollaborator?: boolean;
}) {
  const {
    fields,
    setFilterValues,
    existingFilters,
    visible,
    tagFilters,
    setTagFilters,
    listIdFilters = [],
    setListIdFilters,
    collaboratorFilters = [],
    setCollaboratorFilters,
    tagOperator = "Or",
    setTagAndOperatorFilter = () => {},
    isIncludeCollaborator = false,
    collaboratorOperator,
    setCollaboratorAndOperatorFilter = () => {},
  } = props;
  const { listOperator, setListIdAndOperatorFilter = () => {} } =
    useContext(ContactsContext);

  const [fieldSearch, setFieldSearch] = useState("");
  const [fieldSelectedName, setFieldSelectedName] = useState("");
  const [fieldSelectedIndex, setFieldSelectedIndex] = useState(0);
  const [audience, setAudience] = useState<AudienceType>();
  const [editingFilter, setEditingFilter] = useState<boolean>(false);
  const [editingTagsFilter, setEditingTagsFilter] = useState<boolean>(false);
  const [editingListsFilter, setEditingListsFilter] = useState<boolean>(false);
  const [editingCollaboratorsFilter, setEditingCollaboratorsFilter] =
    useState<boolean>(false);
  const [selectingFilter, setSelectingFilter] = useState(false);
  const [tagOperatorLocal, setTagOperatorLocal] = useState<ConditionOperator>(
    tagFilters.length === 0 ? DefaultOperatorValue : tagOperator
  );
  const [listOperatorLocal, setListOperatorLocal] = useState<ConditionOperator>(
    listIdFilters.length === 0 ? DefaultOperatorValue : listOperator
  );
  const [collaboratorOperatorLocal, setCollaboratorOperatorLocal] =
    useState<ConditionOperator>(
      collaboratorFilters.length === 0
        ? DefaultOperatorValue
        : collaboratorOperator
    );
  const firstRendered = useRef(false);
  const [drawerRef, setDrawerRef] = useState<HTMLElement | null>(null);
  const staffList = useAppSelector((s) => s.staffList, equals);
  const companyId = useAppSelector((s) => s.company?.id);

  useEffect(() => {
    firstRendered.current = true;
  }, []);

  useEffect(() => {
    if (firstRendered.current) {
      setTagOperatorLocal(tagOperator);
    }
  }, [tagOperator]);

  useEffect(() => {
    if (firstRendered.current) {
      setListOperatorLocal(listOperator);
    }
  }, [listOperator]);

  const { getFieldDisplayNameLocale, staticFieldDisplayNames } =
    useFieldLocales();
  const { t } = useTranslation();
  const { companyTags } = useCompanyHashTags();
  const { lists } = useImportedLists();

  const fieldMatcher = getQueryMatcher((f: FilterConfigType) => f.fieldName);

  function searchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFieldSearch(e.target.value);
  }

  function conditionClick(fieldName: string) {
    setFieldSelectedName(fieldName);
    setEditingFilter(true);
    setEditingTagsFilter(false);
    setSelectingFilter(false);
    setEditingListsFilter(false);
  }

  function tagsConditionClick() {
    setFieldSelectedName("");
    setFieldSelectedIndex(0);
    setEditingFilter(false);
    setSelectingFilter(false);
    setEditingTagsFilter(true);
    setEditingListsFilter(false);
  }

  function listsConditionClick() {
    setFieldSelectedName("");
    setFieldSelectedIndex(0);
    setEditingFilter(false);
    setSelectingFilter(false);
    setEditingTagsFilter(false);
    setEditingListsFilter(true);
  }

  function collaboratorConditionClick() {
    setFieldSelectedName("");
    setFieldSelectedIndex(0);
    setEditingFilter(false);
    setSelectingFilter(false);
    setEditingTagsFilter(false);
    setEditingListsFilter(false);
    setEditingCollaboratorsFilter(true);
  }

  function startSelectingFilter() {
    setSelectingFilter(true);
  }

  function updateSelectedItem(audience: AudienceType, index: number) {
    setEditingFilter(true);
    setEditingTagsFilter(false);
    setEditingListsFilter(false);
    setAudience(audience);
    setSelectingFilter(true);
    setFieldSelectedName(audience.fieldName);
    setFieldSelectedIndex(index);
  }

  function stopEditCondition() {
    setEditingTagsFilter(false);
    setEditingListsFilter(false);
    setEditingFilter(false);
    setEditingCollaboratorsFilter(false);
    setFieldSelectedName("");
    setFieldSearch("");
    setSelectingFilter(false);
    setAudience(undefined);
    setTagOperatorLocal(tagOperator);
    setListOperatorLocal(listOperator);
  }

  function updateCondition(
    selectedCondition: string,
    selectedValue: string[],
    type: string,
    index?: number
  ) {
    if (selectedValue) {
      const audienceTypeUpdated = produce(existingFilters, (draft) => {
        if (index !== undefined && draft[index]) {
          draft[index].filterCondition = selectedCondition;
          draft[index].filterValue = selectedValue;
        } else {
          draft.push({
            filterValue: selectedValue,
            filterCondition: selectedCondition,
            fieldName:
              type === "customField" ? fieldSelectedName : selectedCondition,
            fieldType: type,
          });
        }
      });

      setFilterValues(audienceTypeUpdated);
    }
    stopEditCondition();
  }

  function deleteFilter(index: number) {
    let filtersUpdated = [...(existingFilters || [])];
    filtersUpdated.splice(index, 1);
    setAudience(undefined);
    localStorage.removeItem(FILTERS_STORAGE_KEY);
    setFilterValues(filtersUpdated);
  }

  let filterList: FilterConfigType[] = [];

  if (fields.length > 0) {
    const fieldFilters: FilterConfigType[] = fields.map((field) => ({
      fieldName: field.fieldName,
      fieldDisplayName: field.displayName,
      type: "customField",
      fieldType: field.type.toLowerCase(),
      fieldOptions: (field.options || []).map((option, key) => ({
        key: key,
        value: option.value,
        text: getFieldDisplayNameLocale(
          option.customUserProfileFieldOptionLinguals,
          option.value
        ),
      })),
    }));

    const firstName: FilterConfigType = {
      type: "customField",
      fieldName: "firstname",
      fieldDisplayName: staticFieldDisplayNames.firstname,
      fieldType: "singlelinetext",
      fieldOptions: [],
    };
    const lastName: FilterConfigType = {
      type: "customField",
      fieldName: "lastname",
      fieldDisplayName: staticFieldDisplayNames.lastname,
      fieldType: "singlelinetext",
      fieldOptions: [],
    };
    const createdAt: FilterConfigType = {
      type: "customField",
      fieldName: "createdat",
      fieldDisplayName: staticFieldDisplayNames.createdAt,
      fieldType: "datetime",
      fieldOptions: [],
    };
    filterList = [firstName, lastName, ...fieldFilters, createdAt];
  }

  const filtered = existingFilters.length > 0;

  const editingAnyFilter =
    editingFilter ||
    editingTagsFilter ||
    editingListsFilter ||
    editingCollaboratorsFilter;
  const editNoFilter =
    !editingFilter &&
    !editingTagsFilter &&
    !editingListsFilter &&
    !editingCollaboratorsFilter;
  const labelsFound =
    !fieldSearch || "label".includes(fieldSearch.toLowerCase());
  const listsFound =
    !fieldSearch || "lists".includes(fieldSearch.toLowerCase());

  const anyTagsApplied = tagFilters.length > 0;
  const anyListsApplied = listIdFilters.length > 0 && setListIdFilters;
  const anyCollaboratorApplied = collaboratorFilters.length > 0;

  const anyFilterApplied = existingFilters.length > 0;

  return (
    <Drawer
      visible={visible}
      className={"contact"}
      hide={() => {
        stopEditCondition();
        props.onHide();
      }}
    >
      <Header as={"h1"} className={"top"}>
        {t("profile.contacts.sidebar.header.filters")}
        <i
          className={"ui icon close lg-white"}
          onClick={() => props.onHide()}
        />
      </Header>
      {!selectingFilter && editNoFilter && !filtered && (
        <Menu.Item className="first">
          <div className="text section">
            <h2 className={"head"}>
              {t("profile.contacts.sidebar.contactInformation")}
            </h2>
          </div>
        </Menu.Item>
      )}
      {(editingAnyFilter || selectingFilter) && (
        <BackLink onClick={stopEditCondition}>{t("nav.backShort")}</BackLink>
      )}
      {!editingAnyFilter && !selectingFilter && (
        <div className="counter">
          {t("profile.contacts.sidebar.header.counterShow", {
            subtotal: props.numOfContacts,
            count: props.numOfContactsTotal,
          })}
        </div>
      )}
      {!selectingFilter && editNoFilter && (
        <div className={`selections`}>
          {anyTagsApplied && (
            <>
              <FilterSelectedItemView
                filterDisplayValue={tagFilters.reduce<ReactNode[]>(
                  (acc, next) => {
                    const tag = companyTags.find(
                      (t) => t.hashtag === next.hashtag
                    );
                    if (tag) {
                      const tagNode = React.createElement(ChatLabel, { tag });
                      return [...acc, tagNode];
                    }
                    return acc;
                  },
                  []
                )}
                fieldName={""}
                fieldDisplayName={t("profile.staticField.hashtag.name")}
                filterDisplayName={
                  tagOperator === DefaultOperatorValue
                    ? t("profile.condition.hashtag.ContainsAnyOr")
                    : t("profile.condition.hashtag.ContainsAllAnd")
                }
                className={"tags"}
                onDelete={() => {
                  setTagFilters([]);
                }}
                onClick={tagsConditionClick}
              />
              {(anyFilterApplied ||
                anyListsApplied ||
                anyCollaboratorApplied) && (
                <div className={"divider"}>
                  {t("profile.contacts.sidebar.conditions.and")}
                </div>
              )}
            </>
          )}
          {anyListsApplied && (
            <>
              <FilterSelectedItemView
                filterDisplayValue={listIdFilters
                  .reduce<string[]>((acc, next) => {
                    const list = lists.find((l) => String(l.id) === next);
                    return list ? [...acc, list.importName] : acc;
                  }, [])
                  .join(", ")}
                fieldName={""}
                fieldDisplayName={t("profile.staticField.importfrom.name")}
                filterDisplayName={
                  listOperator === DefaultOperatorValue
                    ? t("profile.condition.list.ContainsAnyOr")
                    : t("profile.condition.list.ContainsAllAnd")
                }
                onDelete={() => {
                  setListIdFilters && setListIdFilters([]);
                }}
                onClick={listsConditionClick}
              />
              {(anyFilterApplied || anyCollaboratorApplied) && (
                <div className={"divider"}>
                  {t("profile.contacts.sidebar.conditions.and")}
                </div>
              )}
            </>
          )}
          {anyCollaboratorApplied && (
            <>
              <FilterSelectedItemView
                filterDisplayValue={collaboratorFilters.map((id) => (
                  <CollaboratorItem
                    key={id}
                    staff={staffList.find((s) => getStaffId(s) === id)!}
                  />
                ))}
                fieldName={""}
                className={"collaborators"}
                fieldDisplayName={t("profile.staticField.collaborator.name")}
                filterDisplayName={
                  collaboratorOperator === DefaultOperatorValue
                    ? t("profile.condition.list.ContainsAnyOr")
                    : t("profile.condition.list.ContainsAllAnd")
                }
                onDelete={() => {
                  setCollaboratorFilters && setCollaboratorFilters([]);
                }}
                onClick={collaboratorConditionClick}
              />
              {anyFilterApplied && (
                <div className={"divider"}>
                  {t("profile.contacts.sidebar.conditions.and")}
                </div>
              )}
            </>
          )}
          {existingFilters
            // do not filter anything, keeping the filters indices
            .map((flt, index) => {
              const foundCustomField = filterList.find(
                (field) =>
                  field.fieldName === flt.fieldName ||
                  field.fieldDisplayName === flt.fieldName
              );
              return (
                <>
                  <FilterSelectedItem
                    deleteFilter={() => deleteFilter(index)}
                    filterList={filterList}
                    type={
                      (foundCustomField && foundCustomField.fieldType) || ""
                    }
                    updateSelectedItem={(aud: AudienceType) =>
                      updateSelectedItem(aud, index)
                    }
                    key={index}
                    audience={flt}
                    index={index}
                  />
                  {index + 1 < existingFilters.length && (
                    <div className={"divider"}>
                      {t("profile.contacts.sidebar.conditions.and")}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      )}

      <div className={`add-filter`} ref={setDrawerRef}>
        {!selectingFilter && editNoFilter && (
          <div className={`filter`}>
            <InfoTooltip
              placement={"right"}
              children={t("profile.tooltip.filter.add")}
              trigger={
                <Button primary onClick={startSelectingFilter}>
                  {t("profile.contacts.sidebar.actions.addFilter")}
                </Button>
              }
            />
          </div>
        )}
        {selectingFilter && (
          <div className={`filter-list${fieldSelectedName && " selected"}`}>
            <Input
              type="text"
              fluid
              onChange={searchChange}
              placeholder={t("form.field.search.placeholder.short")}
            />
            <div className="filter-condition-list">
              <AvailableFilterList
                conditionClick={conditionClick}
                prepend={
                  <>
                    {tagFilters.length === 0 && labelsFound && (
                      <li onClick={tagsConditionClick}>
                        {t("profile.staticField.hashtag.name")}
                      </li>
                    )}
                    {listIdFilters.length === 0 &&
                      setListIdFilters &&
                      listsFound && (
                        <li onClick={listsConditionClick}>
                          {t("profile.staticField.lists.name")}
                        </li>
                      )}
                    {collaboratorFilters.length === 0 &&
                      isIncludeCollaborator && (
                        <li onClick={collaboratorConditionClick}>
                          {t("profile.staticField.collaborator.name")}
                        </li>
                      )}
                  </>
                }
                filterItemList={filterList.filter(fieldMatcher(fieldSearch))}
              />
            </div>
          </div>
        )}
        {editingFilter && (
          <EditFilterCondition
            audience={audience}
            key={audience && fieldSelectedIndex}
            index={audience && fieldSelectedIndex}
            isSelected={editingFilter}
            updateCondition={updateCondition}
            filter={filterList.find(
              (f) =>
                f.fieldName.toLowerCase() === fieldSelectedName.toLowerCase()
            )}
          />
        )}
        {editingTagsFilter && (
          <EditTagFilters
            tagsInit={tagFilters}
            operatorLocal={tagOperatorLocal}
            setOperatorLocal={setTagOperatorLocal}
            onFilterApplied={(tags, operator) => {
              setTagAndOperatorFilter(tags, operator);
              stopEditCondition();
            }}
          />
        )}
        {editingCollaboratorsFilter && (
          <EditCollaboratorsFilters
            operatorLocal={collaboratorOperatorLocal}
            setOperatorLocal={setCollaboratorOperatorLocal}
            collaboratorInit={collaboratorFilters}
            drawerNode={drawerRef}
            onFilterApplied={(ids, operator) => {
              setCollaboratorAndOperatorFilter(ids, operator);
              stopEditCondition();
            }}
          />
        )}
        {editingListsFilter && listIdFilters && setListIdFilters && (
          <EditListFilters
            listIdsInit={listIdFilters}
            operatorLocal={listOperatorLocal}
            setOperatorLocal={setListOperatorLocal}
            onFilterApplied={(ids, operator) => {
              setListIdAndOperatorFilter(ids, operator);
              stopEditCondition();
            }}
          />
        )}
      </div>
    </Drawer>
  );
}

function EditTagFilters(props: {
  tagsInit: HashTagCountedType[];
  operatorLocal: ConditionOperator;
  setOperatorLocal: React.Dispatch<React.SetStateAction<ConditionOperator>>;
  onFilterApplied: (
    tags: HashTagCountedType[],
    operator: ConditionOperator
  ) => void;
}) {
  const { t } = useTranslation();
  const { companyTags } = useCompanyHashTags();
  const [tagFiltersLocal, setTagFiltersLocal] = useState<HashTagCountedType[]>(
    props.tagsInit
  );

  return (
    <div className={`filter-condition display tags`}>
      <div className="ui form filter-content">
        <div className={"filter-name"}>
          {t("profile.staticField.hashtag.name")}
        </div>
        <div className="field">
          <ConditionCheckboxBlock
            name="tag"
            operatorLocal={props.operatorLocal}
            setOperatorLocal={props.setOperatorLocal}
          />
          <TagInput
            companyTags={companyTags}
            onChange={setTagFiltersLocal}
            value={tagFiltersLocal}
          />
        </div>
      </div>
      <Button
        content={t("profile.form.filter.action.apply")}
        className={"button-small"}
        primary
        onClick={() => {
          props.onFilterApplied(tagFiltersLocal, props.operatorLocal);
        }}
      />
    </div>
  );
}

export function EditListFilters(props: {
  listIdsInit: string[];
  operatorLocal: ConditionOperator;
  setOperatorLocal: React.Dispatch<React.SetStateAction<ConditionOperator>>;
  onFilterApplied: (ids: string[], operator: ConditionOperator) => void;
}) {
  const { t } = useTranslation();
  const { lists } = useImportedLists();
  const [listIdsLocal, setListIdsLocal] = useState<string[]>(props.listIdsInit);

  return (
    <div className={`filter-condition display tags`}>
      <div className="ui form filter-content">
        <div className={"filter-name"}>
          {t("profile.staticField.importfrom.name")}
        </div>
        <div className="field">
          <ConditionCheckboxBlock
            name="list"
            operatorLocal={props.operatorLocal}
            setOperatorLocal={props.setOperatorLocal}
          />
          <Dropdown
            fluid
            placeholder={t("profile.contacts.grid.search", {
              name: t("profile.staticField.importfrom.name"),
            })}
            value={listIdsLocal}
            onChange={(_, { value }) => {
              setListIdsLocal(value as string[]);
            }}
            options={lists.map((l) => ({
              value: String(l.id),
              text: l.importName,
              key: l.id,
            }))}
            multiple
            search
          />
        </div>
      </div>
      <Button
        content={t("profile.form.filter.action.apply")}
        className={"button-small"}
        primary
        onClick={() => {
          props.onFilterApplied(listIdsLocal, props.operatorLocal);
        }}
      />
    </div>
  );
}

export function EditCollaboratorsFilters(props: {
  collaboratorInit: string[];
  operatorLocal: ConditionOperator;
  setOperatorLocal: React.Dispatch<React.SetStateAction<ConditionOperator>>;
  drawerNode: HTMLElement | null;
  onFilterApplied: (tags: string[], operator: ConditionOperator) => void;
}) {
  const { t } = useTranslation();
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);
  const [assignOpened, setAssignOpened] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [staffIds, setStaffIds] = useState<string[]>(props.collaboratorInit);
  const { teams } = useTeams();
  const staffList = useAppSelector((s) => s.staffList, equals);
  const { isCollapsed, teamsCollapsible, toggleCollapse } = useCollapsibleTeams(
    {
      allTeams: teams,
      searchQuery: searchText,
      opened: assignOpened,
      isUserVisible: (u) => !staffIds.includes(u.userInfo.id),
    }
  );

  const renderStaff = (staff: StaffType) => (
    <div
      className={"item search-staff"}
      onClick={() => {
        setStaffIds([...staffIds, getStaffId(staff)]);
      }}
      key={getStaffId(staff)}
    >
      <div className="staff">
        <StaffItemAvatar
          staff={{
            name: staffDisplayName(staff),
            profilePicture: staff.profilePicture,
            profilePictureURL: staff.profilePictureURL,
            ...staff.userInfo,
          }}
        />
        <div className="info">
          <div className="name">{staffDisplayName(staff)}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`filter-condition display tags`}>
      <div className="ui form filter-content">
        <div className={"filter-name"}>
          {t("profile.staticField.collaborator.name")}
        </div>
        <div className="field">
          <ConditionCheckboxBlock
            name="collaborator"
            operatorLocal={props.operatorLocal}
            setOperatorLocal={props.setOperatorLocal}
          />
          <div ref={setButtonRef}>
            <div className={styles.collaboratorInput}>
              {staffIds.length === 0 && (
                <div className={styles.placeholder}>
                  {t("profile.staticField.collaborator.placeholder")}
                </div>
              )}
              {staffIds.map((id) => (
                <CollaboratorItem
                  key={id}
                  staff={staffList.find((s) => getStaffId(s) === id)!}
                  removeItem={() => {
                    const restIds = staffIds.filter(
                      (staffId) => staffId !== id
                    );
                    setStaffIds(restIds);
                  }}
                />
              ))}
            </div>
            <AssignmentPopup
              opened={assignOpened}
              setOpened={setAssignOpened}
              anchorNode={buttonRef}
              offset={[0, 12]}
              placement={"bottom-start"}
              className={styles.collaboratorFilterSelector}
              mountNode={props.drawerNode}
            >
              <Dropdown.Menu>
                <Input
                  icon="search"
                  fluid
                  iconPosition="left"
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  placeholder={t("chat.form.input.search.placeholder")}
                />
                <Dropdown.Menu scrolling className={"team-list"}>
                  {teamsCollapsible.map((team) => {
                    return (
                      <TeamCollapsible
                        key={team.id}
                        team={team}
                        collapsed={
                          searchText.length > 0 ? false : isCollapsed(team.id)
                        }
                        toggle={toggleCollapse}
                        renderItem={renderStaff}
                      />
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </AssignmentPopup>
          </div>
        </div>
      </div>
      <Button
        content={t("profile.form.filter.action.apply")}
        className={"button-small"}
        primary
        onClick={() => {
          props.onFilterApplied(staffIds, props.operatorLocal);
        }}
      />
    </div>
  );
}

export default ContactSidebar;
