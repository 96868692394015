import { Image } from "semantic-ui-react";
import Avatar from "react-avatar";
import React from "react";
import { StaffListType } from "./types";
import { getUserPicUrl } from "../../api/User/getUserPicUrl";

export function StaffItemAvatar(props: {
  staff: StaffListType;
  hideText?: boolean;
}) {
  const name = props.staff.displayName || props.staff.email;

  return (
    <>
      <span className={"staff-item-avatar"}>
        {getUserPicUrl(props.staff) ? (
          <Image size={"tiny"} src={getUserPicUrl(props.staff)} avatar />
        ) : (
          <Avatar name={name} round={true} maxInitials={2} />
        )}
      </span>
    </>
  );
}
