import React from "react";
import { Checkbox } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ConditionOperator } from "../../../container/Contact/hooks/ContactsStateType";
import styles from "./ConditionCheckboxBlock.module.css";

export const ConditionCheckboxBlock = (props: {
  name: string;
  operatorLocal: ConditionOperator;
  setOperatorLocal: React.Dispatch<React.SetStateAction<ConditionOperator>>;
}) => {
  const { t } = useTranslation();
  const { operatorLocal, setOperatorLocal, name } = props;

  return (
    <div className={styles.conditionContainer}>
      <Checkbox
        label={
          name === "tag"
            ? t("profile.condition.hashtag.ContainsAnyOr")
            : t("profile.condition.list.ContainsAnyOr")
        }
        onClick={() => setOperatorLocal("Or")}
        checked={operatorLocal === "Or"}
      />
      <Checkbox
        label={
          name === "tag"
            ? t("profile.condition.hashtag.ContainsAllAnd")
            : t("profile.condition.list.ContainsAllAnd")
        }
        onClick={() => setOperatorLocal("And")}
        checked={operatorLocal === "And"}
      />
    </div>
  );
};
