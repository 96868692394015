import { postWithExceptions } from "../apiRequest";
import { RegisterInfoType } from "../../container/Signup/signupReducer";
import { UserType } from "../../types/LoginType";

export async function sendRegisterAccountCompany(
  registerInfo: Omit<
    RegisterInfoType,
    "isEmptyChannel" | "firstName" | "lastName"
  > & {
    webClientUUID: string;
    firstName: string | null;
    lastName: string | null;
  },
  timeInfoId: string
) {
  const {
    firstName,
    lastName,
    companyName,
    phoneNumber,
    promoCode,
    heardFrom,
    webClientUUID,
    industry,
    onlineShopSystem,
    channels,
    companySize,
    companyWebsite,
    isAgreeMarketingConsent,
  } = registerInfo;
  let referralId = "";
  const tolt = window["tolt_referral"];
  if (tolt) {
    referralId = tolt;
  }

  return await postWithExceptions<
    UserType,
    {
      param: Omit<
        RegisterInfoType,
        "channels" | "promoCode" | "isEmptyChannel" | "firstName" | "lastName"
      > & {
        firstName: string | null;
        lastName: string | null;
        webClientUUID: string;
        subscriptionPlanId: string;
        promotionCode: string;
        communicationTools?: string[];
        referral: string;
        lmref: string; // empty string is ok
        displayName: string;
      };
    }
  >("/auth0/account/RegisterAccountCompany", {
    param: {
      lmref: "",
      phoneNumber,
      industry,
      onlineShopSystem,
      companyName,
      timeZoneInfoId: timeInfoId || "GMT Time Zone",
      companySize,
      subscriptionPlanId: "sleekflow_freemium",
      heardFrom,
      promotionCode: promoCode,
      webClientUUID,
      referral: referralId,
      firstName,
      lastName,
      displayName: `${firstName} ${lastName}`,
      communicationTools: channels,
      companyWebsite: companyWebsite,
      isAgreeMarketingConsent,
    },
  });
}
