import { HashTagCountedType } from "../../../types/ConversationType";
import { ChatLabel } from "../../Chat/ChatLabel";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import { ascend, prop, propEq, remove } from "ramda";
import { useTranslation } from "react-i18next";

export function TagInput(props: {
  companyTags: HashTagCountedType[];
  onChange: (tags: HashTagCountedType[]) => void;
  value: HashTagCountedType[];
}) {
  const { t } = useTranslation();
  let { companyTags, onChange, value } = props;
  const choices = companyTags.map((label, idx) => {
    return {
      value: label.id,
      content: <ChatLabel tag={label} />,
      text: label.hashtag,
      key: idx + 1,
    };
  });

  return (
    <Dropdown
      multiple
      scrolling
      selection
      fluid
      search
      selectOnBlur={false}
      options={[...choices].sort(ascend(prop("text")))}
      value={value.map(prop("id"))}
      placeholder={t("profile.contacts.grid.search", {
        name: t("profile.staticField.hashtag.name"),
      })}
      onChange={(_, data) => {
        const tagValues = data.value as string[];
        const tags = companyTags.filter((ctag) =>
          tagValues.some((v) => v === ctag.id)
        );
        onChange(tags);
      }}
      renderLabel={(item, idx) => {
        const tag = companyTags.find(propEq("id", item.value));
        if (!tag) {
          return null;
        }
        return (
          <ChatLabel
            tag={tag}
            onDismiss={() => {
              const tagsWithoutThis = remove(idx, 1, value);
              onChange(tagsWithoutThis);
            }}
          />
        );
      }}
    />
  );
}
