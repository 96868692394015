import { CustomProfileField } from "../../../types/ContactType";
import { object, string, TestContext } from "yup";
import { FieldValue } from "../NewContact/NewContact";
import { useTranslation } from "react-i18next";
import { parseAndFormatAnyPhone } from "../../Channel/selectors";
import { TFunction } from "i18next";
import { useCallback } from "react";

interface ContactTestContext {
  phones?: any[];
  emails?: any[];
}

export const REQUIRED_PERSON_FIELDS = ["Email", "PhoneNumber"];

export function useValidateContact() {
  const { t } = useTranslation();

  const validatePhone = useCallback(createPhoneValidator(t), []);
  const validateContactFields = useCallback(createFieldsValidator(t), []);
  return {
    validatePhone: validatePhone,
    validateContactFields: validateContactFields,
  };
}

export function createPhoneValidator(t: TFunction) {
  return function (message?: string, allowEmpty: boolean = false) {
    return string().test(
      "phone-is-valid",
      message ?? t("form.profile.field.phone.error.format"),
      function (this: TestContext, value?: any) {
        if (!value) {
          if (!allowEmpty) {
            throw this.createError({ path: this.path, message });
          }
          return true;
        }
        let context = this.options.context as ContactTestContext;
        const parsed = parseAndFormatAnyPhone(value as string);
        let anyEmailIsFilled = context?.emails?.some((email) =>
          string().required().isValidSync(email)
        );
        return anyEmailIsFilled ? true : parsed !== undefined;
      }
    );
  };
}

export function createFieldsValidator(t: TFunction) {
  const validatePhone = createPhoneValidator(t);
  const emailSchema = () =>
    string().email(t("form.profile.field.email.error.format"));

  return <T extends FieldValue>(fields: CustomProfileField[]) => {
    let contactSchema = {
      firstname: string(),
      lastname: string(),
    };

    let phoneFields = fields.filter(
      (f) => f.type.toLowerCase() === "phonenumber"
    );
    let emailFields = fields.filter((f) => f.type.toLowerCase() === "email");

    contactSchema = phoneFields.reduce(
      (shape, field) => ({
        ...shape,
        [field.fieldName]: string()
          .ensure()
          .trim()
          .test(
            "has-any-emails",
            t("form.profile.field.emailOrPhone.error.invalid"),
            function (this: TestContext, value?: any) {
              if (!REQUIRED_PERSON_FIELDS.includes(this.path)) {
                return true;
              }
              let context = this.options.context as ContactTestContext;
              let anyEmailIsFilled = context?.emails?.some((email) =>
                string().required().email().isValidSync(email)
              );
              return anyEmailIsFilled
                ? string()
                    .concat(
                      validatePhone(
                        t("form.profile.field.phone.error.format"),
                        true
                      )
                    )
                    .isValidSync(value)
                : string().required().isValidSync(value);
            }
          )
          .concat(
            validatePhone(t("form.profile.field.phone.error.format"), true)
          ),
      }),
      contactSchema
    );

    contactSchema = emailFields.reduce(
      (shape, field) => ({
        ...shape,
        [field.fieldName]: string()
          .ensure()
          .trim()
          .test(
            "has-any-emails",
            t("form.profile.field.emailOrPhone.error.invalid"),
            function (this: TestContext, value?: any) {
              if (!REQUIRED_PERSON_FIELDS.includes(this.path)) {
                return true;
              }
              let context = this.options.context as ContactTestContext;
              let anyPhoneIsFilled = context?.phones?.some((phone) =>
                string()
                  .required()
                  .concat(
                    validatePhone(
                      t("form.profile.field.phone.error.format"),
                      false
                    )
                  )
                  .isValidSync(phone)
              );
              return anyPhoneIsFilled
                ? true
                : string().required().isValidSync(value);
            }
          )
          .concat(emailSchema()),
      }),
      contactSchema
    );

    return async (values: T): Promise<T | {}> => {
      let emailValues = emailFields.reduce(
        (emailValues: string[], field: CustomProfileField) => {
          if (values[field.fieldName]) {
            emailValues.push(values[field.fieldName]);
          }
          return emailValues;
        },
        []
      );

      let phoneValues = phoneFields.reduce(
        (phoneValues: string[], field: CustomProfileField) => {
          if (values[field.fieldName]) {
            phoneValues.push(values[field.fieldName]);
          }
          return phoneValues;
        },
        []
      );

      return object()
        .shape(contactSchema)
        .validate(values, {
          context: {
            emails: emailValues,
            phones: phoneValues,
          },
        });
    };
  };
}
