import { getUserPicUrl } from "../../../api/User/getUserPicUrl";
import { Image } from "semantic-ui-react";
import { staffDisplayName } from "../../Chat/utils/staffDisplayName";
import React from "react";
import { StaffType } from "../../../types/StaffType";
import { Avatar } from "./Avatar";

export function StaffAvatar(props: { staff: StaffType; size: string }) {
  const { staff, size } = props;
  const profilePictureUrl = getUserPicUrl(staff);
  return (
    <>
      {profilePictureUrl ? (
        <Image size={"tiny"} src={profilePictureUrl} avatar />
      ) : (
        <Avatar name={staffDisplayName(staff)} size={size} />
      )}
    </>
  );
}
