import React from "react";
import useRouteConfig from "../../../config/useRouteConfig";
import { ContactListType } from "../../../types/ContactType";
import styles from "./ContactListRow.module.css";
import { htmlEscape } from "../../../lib/utility/htmlEscape";

const ContactListRow = (props: { tag: ContactListType }) => {
  const { routeTo } = useRouteConfig();
  const { tag } = props;
  return (
    <div className={`${styles.tagsContainer} ui button button-small`}>
      <a
        rel="noreferrer noopener"
        target="_blank"
        href={routeTo(`/contacts/lists/${tag.id}`, true)}
      >
        <span className={`tag-text`} title={htmlEscape(tag.listName)}>
          {tag.listName}
        </span>
      </a>
    </div>
  );
};
export default ContactListRow;
