import { useCallback, useState, useLayoutEffect } from "react";
import { parseCssTime } from "../utility/parseCssTime";

export function useVerticalCurtain(props: {
  curtain: HTMLElement | null;
  contents: HTMLElement | null;
  opened: boolean;
}) {
  const { curtain, contents, opened } = props;
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useLayoutEffect(() => {
    if (!(curtain && contents)) {
      return;
    }
    if (opened) {
      // reset any particular height set before
      curtain.style.height = `auto`;
    } else {
      curtain.style.height = "0";
    }
  }, [curtain, contents]);

  const toggle = useCallback(
    (open: boolean) => {
      if (!(curtain && contents)) {
        return;
      }
      const durationStyled = window.getComputedStyle(curtain)
        .transitionDuration;
      let duration = undefined;
      if (durationStyled) {
        duration = parseCssTime(durationStyled);
      }
      const contentHeight = contents.getBoundingClientRect().height;

      if (open) {
        curtain.style.height = `${contentHeight}px`;
        if (duration) {
          const timeoutIdNew = setTimeout(
            () => (curtain.style.height = "auto"),
            duration
          );
          setTimeoutId(timeoutIdNew);
        } else {
          // when there is no duration, set height to auto immediately
          curtain.style.height = `auto`;
        }
      } else {
        // set animation start value instead of "auto"
        curtain.style.height = `${contentHeight}px`;
        // run animation with a micro-delay
        const timeoutIdNew = setTimeout(() => {
          curtain.style.height = `0`;
        }, 10);
        setTimeoutId(timeoutIdNew);
      }
    },
    [curtain, contents]
  );

  const cancel = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  useLayoutEffect(() => {
    toggle(props.opened);
    return () => {
      cancel();
    };
  }, [props.opened]);

  return { toggle, cancel };
}
