import React, {
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { PostLogin } from "../component/Header";

import { deleteMethod } from "../api/apiRequest";
import { DELETE_USERPROFILE } from "../api/apiPath";
import NewContact from "../component/Contact/NewContact/NewContact";
import ContactMain, { isListFilter } from "./ContactMain";
import {
  AudienceFilterConditionType,
  AudienceType,
  toApiCondition,
} from "../types/BroadcastCampaignType";
import { useHistory, useLocation } from "react-router";
import Helmet from "react-helmet";
import ProfileSearchType from "../types/ProfileSearchType";
import {
  SelectAllContext,
  UpdatePageEvent,
} from "../xstate/selectAllIItemsMachine";
import { useFlashMessageChannel } from "../component/BannerMessage/flashBannerMessage";
import ContactSidebar from "../component/Contact/ContactSidebar";
import { contactReducer } from "./Contact/hooks/contactReducer";
import {
  ConditionOperator,
  ContactActionType,
  ContactsContext,
  ContactsStateType,
  DefaultOperatorValue,
  getDefaultStateValue,
} from "./Contact/hooks/ContactsStateType";
import { HashTagCountedType } from "../types/ConversationType";
import Cookies from "js-cookie";
import { isFreemiumPlan } from "../types/PlanSelectionType";
import {
  isNotCollaboratorColumn,
  isNotLabelsColumn,
  isNotListsColumn,
} from "./Contact/hooks/useCustomProfileFields";
import { useTeams } from "./Settings/useTeams";
import { useTranslation } from "react-i18next";
import {
  ContactsRequestPageExtensionType,
  useFetchContactsPage,
} from "../api/Contacts/useFetchContactsPage";
import { equals, innerJoin, pick } from "ramda";
import produce from "immer";
import useImportedLists from "./Contact/Imported/useImportedLists";
import useFetchCompany from "../api/Company/useFetchCompany";
import { useSelectAllBehavior } from "../xstate/hooks/useSelectAllBehavior";
import { useAppDispatch, useAppSelector } from "../AppRootContext";
import moment from "moment";
import { useContactsSearchFilter } from "../api/Contacts/useContactsSearchFilter";

export function normalizeConditions(
  filters: AudienceType[],
  tags: HashTagCountedType[],
  utcOffset: number
) {
  return filters
    .reduce<AudienceFilterConditionType[]>((acc, filter) => {
      const normalized = toApiCondition(filter);
      if (normalized) {
        return acc.concat(normalized);
      }
      return acc;
    }, [])
    .concat(
      tags.map((tag) => ({
        containHashTag: tag.hashtag,
        nextOperator: "Or",
      }))
    );
}

export type NormalizedSearchResponseType = {
  items: ProfileSearchType[];
  totalFiltered: number;
  totalUnfiltered: number;
  pageIds: string[];
  targetIdsCount: number;
  selectedIds?: string[];
};

type SortedByDirectionType = "ASC" | "DESC" | undefined;
export type SortParamType = { field: string; direction: SortedByDirectionType };

export const sortFieldNameMap = {
  displayName: "displayname",
  updatedAt: "updatedat",
  createdAt: "createdat",
  LastContact: "lastContact",
  LastContactFromCustomers: "lastContactFromCustomers",
} as const;

export const sortFieldDirectionMap = {
  ASC: "asc",
  DESC: "desc",
} as const;

export const FILTERS_STORAGE_KEY = "contacts.filterList";
export const FILTERS_LISTS_STORAGE_KEY = "contacts.filterListIds";
export const FILTERS_LISTS_OPERATOR_STORAGE_KEY =
  "contacts.filterListIdsOperator";
export const FILTERS_TAGS_STORAGE_KEY = "contacts.filterTags";
export const FILTERS_TAGS_OPERATOR_STORAGE_KEY = "contacts.filterTagsOperator";
export const FILTERS_COLLABORATOR_STORAGE_KEY = "contacts.filterCollaborators";
export const FILTERS_COLLABORATOR_OPERATOR_STORAGE_KEY =
  "contacts.filterCollaboratorsOperator";
export const FILTERS_LAST_CONTACT_FILTER_DATETIME =
  "contact.lastFilterDateTime";
export const CLEAR_CACHE_TIMERANGE = 30;

export function notQuickCondition(cond: AudienceType) {
  const SKIP_FIELDS = [
    "lastname",
    "firstname",
    "email",
    "phone",
    "displayname",
  ];
  return !SKIP_FIELDS.includes(cond.fieldName.toLowerCase());
}

function Contact() {
  const { refreshTeams } = useTeams();
  const LIMIT = 30;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [state, dispatch] = useReducer<
    Reducer<ContactsStateType, ContactActionType>
  >(contactReducer, {
    ...getDefaultStateValue(),
    filters: [],
  });
  const loginDispatch = useAppDispatch();
  const { staffList, profile, currentPlan } = useAppSelector(
    pick(["staffList", "profile", "currentPlan"]),
    equals
  );

  const { state: selectAllMachineState, send: selectAllSend } =
    useSelectAllBehavior({
      selectAllCallback: async () => {
        const { totalResult, userProfileIds } = await selectAll(
          getAllAppliedFilters()
        );
        return {
          total: totalResult,
          targetIds: userProfileIds,
        };
      },
    });
  const [displayPopup, setDisplayPopup] = useState(false);
  const flash = useFlashMessageChannel();
  const { buildFilters } = useContactsSearchFilter(
    state.filters,
    state.tagFilters,
    state.quickFilter.query,
    state.listIdFilters,
    state.collaboratorFilters
  );

  const { selectAll, fetchContactsPage } = useFetchContactsPage(
    buildFilters,
    state.quickFilter.query
  );

  const { lists, loading: listsLoading, listAdded } = useImportedLists();

  const { company, refreshCompany } = useFetchCompany();

  const removeLocalStorage = () => {
    localStorage.removeItem(FILTERS_STORAGE_KEY);
    localStorage.removeItem(FILTERS_TAGS_STORAGE_KEY);
    localStorage.removeItem(FILTERS_LISTS_STORAGE_KEY);
    localStorage.removeItem(FILTERS_LAST_CONTACT_FILTER_DATETIME);
    localStorage.removeItem(FILTERS_TAGS_OPERATOR_STORAGE_KEY);
    localStorage.removeItem(FILTERS_LISTS_OPERATOR_STORAGE_KEY);
    localStorage.removeItem(FILTERS_COLLABORATOR_STORAGE_KEY);
    localStorage.removeItem(FILTERS_COLLABORATOR_OPERATOR_STORAGE_KEY);
  };
  const isLoadPageBlocked =
    listsLoading || !Boolean(company?.customUserProfileFields);
  const getAllAppliedFilters = useCallback(
    (page?: number) => ({
      page: page ?? state.activePage,
      listIds: state.listIdFilters,
      listOperator: state.listOperator,
      tags: state.tagFilters,
      tagOperator: state.tagOperator,
      sort: state.sortParams,
      filters: state.filters,
    }),
    [
      state.activePage,
      state.listIdFilters,
      state.listOperator,
      state.tagFilters,
      state.tagOperator,
      state.sortParams,
      state.filters,
    ]
  );

  useEffect(() => {
    const lastFilteredDateTime = localStorage.getItem(
      FILTERS_LAST_CONTACT_FILTER_DATETIME
    );
    if (
      lastFilteredDateTime &&
      moment(Number(lastFilteredDateTime)).isValid()
    ) {
      if (
        moment().isSameOrAfter(
          moment(Number(lastFilteredDateTime)).add(
            CLEAR_CACHE_TIMERANGE,
            "minutes"
          )
        )
      ) {
        removeLocalStorage();
      }
    }
    // update the company once in case we came from a first login after registration
    refreshCompany();
  }, []);

  useEffect(() => {
    if (isLoadPageBlocked) {
      return;
    }
    const params = new URLSearchParams(location.search.slice(1));
    const sortedFieldParam = params.get("sortedField");
    const sortedByParam = params.get("sortedBy");
    let filters = state.filters;
    let tags = state.tagFilters;
    let sortParams = state.sortParams;
    let listIds: string[] = [];
    let collaboratorIds: string[] = state.collaboratorFilters;
    let tagOperator: ConditionOperator = state.tagOperator;
    let listOperator: ConditionOperator = state.listOperator;
    let collaboratorOperator: ConditionOperator = state.collaboratorOperator;

    const filtersStored = localStorage.getItem(FILTERS_STORAGE_KEY);
    const tagsStored = localStorage.getItem(FILTERS_TAGS_STORAGE_KEY);
    const listsStored = localStorage.getItem(FILTERS_LISTS_STORAGE_KEY);
    const collaboratorStored = localStorage.getItem(
      FILTERS_COLLABORATOR_STORAGE_KEY
    );
    const tagOperatorStored = localStorage.getItem(
      FILTERS_TAGS_OPERATOR_STORAGE_KEY
    );
    const listOperatorStored = localStorage.getItem(
      FILTERS_LISTS_OPERATOR_STORAGE_KEY
    );
    const collaboratorOperatorStored = localStorage.getItem(
      FILTERS_COLLABORATOR_OPERATOR_STORAGE_KEY
    );

    try {
      if (filtersStored) {
        filters = JSON.parse(filtersStored) as AudienceType[];
      }
      if (tagsStored) {
        tags = JSON.parse(tagsStored) as HashTagCountedType[];
      }
      if (tagOperatorStored) {
        tagOperator = JSON.parse(tagOperatorStored) as ConditionOperator;
      }
      if (listsStored) {
        listIds = JSON.parse(listsStored) as string[];
      }
      if (listOperatorStored) {
        listOperator = JSON.parse(listOperatorStored) as ConditionOperator;
      }
      if (collaboratorStored) {
        collaboratorIds = JSON.parse(collaboratorStored) as string[];
      }
      if (collaboratorOperatorStored) {
        collaboratorOperator = JSON.parse(
          collaboratorOperatorStored
        ) as ConditionOperator;
      }
    } catch (e) {
      console.error("Init from localStorage", e);
      removeLocalStorage();
    }
    dispatch({
      type: "UPDATE_QUERY",
      filters,
      tags,
      page: 1,
      listIds,
      tagOperator,
      listOperator,
      collaboratorIds,
      collaboratorOperator,
    });
    if (sortedFieldParam && sortedByParam) {
      sortParams = [
        {
          field: sortedFieldParam,
          direction: sortedByParam as SortedByDirectionType,
        },
      ];
    }
    loadPage({
      filters,
      tags,
      sort: sortParams,
      page: 1,
      listIds,
      listOperator,
      collaboratorIds,
      collaboratorOperator,
    });
    refreshTeams();
  }, [isLoadPageBlocked]);

  useEffect(() => {
    if (
      isFreemiumPlan(currentPlan) &&
      Cookies.get("isDisplayedContactGuide") === undefined
    ) {
      loginDispatch({
        type: "SHOW_CONTACT_GUIDE",
      });
    }
  }, [Cookies.get("isDisplayedContactGuide") === undefined, currentPlan.id]);

  useEffect(() => {
    if (listsLoading || lists.length === 0) {
      return;
    }
    const initListIds = state.filters
      .filter(isListFilter)
      ?.map((f) => f.filterValue[0]);
    const actualListsInitIds = innerJoin(
      (id, list) => id === String(list.id),
      initListIds,
      lists
    );
    if (actualListsInitIds.length === 0) {
      return;
    }
    setFilterValuesList(
      produce(state.filters, (draftState) => {
        draftState.filter(isListFilter).forEach((f) => {
          f.filterValue = actualListsInitIds;
        });
      })
    );
  }, [listsLoading]);

  const loadPage = useCallback(
    async (extend: ContactsRequestPageExtensionType = {}) => {
      dispatch({ type: "PAGE_LOAD_START" });
      try {
        const page = extend.page ?? state.activePage;
        const result = await fetchContactsPage(
          page,
          extend.sort ?? state.sortParams,
          LIMIT,
          extend,
          selectAllMachineState.matches("selectedAll")
        );
        dispatch({
          type: "PAGE_LOAD_END",
          itemsTotal: result.totalFiltered,
          itemsTotalUnfiltered: result.totalUnfiltered,
          pagesTotal: Math.ceil(result.totalFiltered / LIMIT),
          profiles: result.items || [],
          page,
        });
        let event: UpdatePageEvent = {
          type: "UPDATE_SELECTION",
          pageIds: result.items.map((i) => i.id),
          total: result.totalFiltered,
          totalUnfiltered: result.totalUnfiltered,
          targetIds:
            result.selectedIds ?? selectAllMachineState.context.targetIds,
          pageSize: LIMIT,
          pageNumber: page,
          pagesCount: Math.ceil(result.totalFiltered / LIMIT),
        };
        // update state machine on external conditions changed
        selectAllSend(event);
      } catch (e) {
        console.error(e);
        dispatch({ type: "PAGE_LOAD_STOP" });
      }
    },
    [state.activePage, JSON.stringify(state.sortParams), fetchContactsPage]
  );

  const refreshPage = async () => {
    await loadPage({ filters: state.filters, page: 1 });
    const selectedIds = await selectAllIds();
    flash(t("flash.profile.bulkEdit.saved", { count: selectedIds.length }));
  };

  const handleContactCreate = useCallback(
    async (isVisible: boolean | undefined) => {
      setTimeout(() => {
        if (!isVisible) {
          dispatch({ type: "HIDE_NEW_CONTACT_FORM" });
        }
      }, 1500);
      await loadPage();
    },
    [loadPage, dispatch]
  );

  const handlePageChange = useCallback(
    async (page: number) => {
      await loadPage(getAllAppliedFilters(page));
    },
    [loadPage, getAllAppliedFilters]
  );

  const selectAllIds = useCallback(async () => {
    if (selectAllMachineState.matches("selectedAll")) {
      const result = await selectAll(getAllAppliedFilters());
      return result.userProfileIds;
    } else {
      return (selectAllMachineState!.context! as SelectAllContext).targetIds;
    }
  }, [
    selectAll,
    selectAllMachineState.matches("selectedAll"),
    selectAllMachineState.context.targetIds.join(),
  ]);

  const deleteProfile = useCallback(async () => {
    dispatch({ type: "CONTACT_DELETE_START" });
    try {
      const selectedProfiles = await selectAllIds();
      await deleteMethod(DELETE_USERPROFILE, {
        param: { UserProfileIds: selectedProfiles },
      });
      await loadPage();
      flash(
        t("flash.settings.contact.deleted", { count: selectedProfiles.length })
      );
    } catch (e) {
      console.error("deleteProfile", e);
    } finally {
      dispatch({ type: "CONTACT_DELETE_END" });
    }
  }, [dispatch, selectAllIds]);

  const handleSort = useCallback(
    async (param: SortParamType) => {
      const sortParamsMerged = [param];
      const query = new URLSearchParams(location.search.slice(1));
      if (
        query.has("sortedField") ||
        query.has("sortedBy") ||
        !(query.has("sortedField") && query.has("sortedBy"))
      ) {
        query.set("sortedField", param.field);
        query.set("sortedBy", param.direction || "ASC");
      }
      history.replace({
        pathname: location.pathname,
        search: query.toString(),
      });
      dispatch({ type: "UPDATE_QUERY", sort: sortParamsMerged });

      await loadPage({ sort: [param] });
    },
    [loadPage, location.search, location.pathname]
  );

  const setFilterValuesList = useCallback(
    (filters: AudienceType[]) => {
      const newFilters = filters ?? [];
      dispatch({
        type: "UPDATE_QUERY",
        filters: newFilters,
        page: 1,
      });
      localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(newFilters));
      localStorage.setItem(
        FILTERS_LAST_CONTACT_FILTER_DATETIME,
        new Date().getTime().toString()
      );
      loadPage({ filters: newFilters, page: 1 });
    },
    [loadPage]
  );

  const executeQuickSearch = useCallback(
    async (search: string) => {
      return await loadPage({ quickSearch: search, page: 1 });
    },
    [loadPage]
  );

  const handleQuickSearchChange = useCallback(
    (text: string) => {
      dispatch({ type: "QUICK_FILTER.UPDATE", value: text });
    },
    [dispatch]
  );

  const setTagFilters = useCallback(
    (tags: HashTagCountedType[]) => {
      dispatch({ type: "UPDATE_QUERY", tags });
      localStorage.setItem(
        FILTERS_LAST_CONTACT_FILTER_DATETIME,
        new Date().getTime().toString()
      );
      localStorage.setItem(FILTERS_TAGS_STORAGE_KEY, JSON.stringify(tags));
      loadPage({ tags, page: 1 });
    },
    [loadPage]
  );

  const setListFilters = useCallback(
    (ids: string[]) => {
      dispatch({ type: "UPDATE_QUERY", listIds: ids });
      localStorage.setItem(
        FILTERS_LAST_CONTACT_FILTER_DATETIME,
        new Date().getTime().toString()
      );
      localStorage.setItem(FILTERS_LISTS_STORAGE_KEY, JSON.stringify(ids));
      loadPage({ listIds: ids, page: 1 });
    },
    [loadPage]
  );

  const setCollaboratorFilters = useCallback(
    (ids: string[]) => {
      dispatch({ type: "UPDATE_QUERY", collaboratorIds: ids });
      localStorage.setItem(
        FILTERS_LAST_CONTACT_FILTER_DATETIME,
        new Date().getTime().toString()
      );
      localStorage.setItem(
        FILTERS_COLLABORATOR_STORAGE_KEY,
        JSON.stringify(ids)
      );
      loadPage({
        collaboratorIds: ids,
        page: 1,
      });
    },
    [loadPage]
  );

  const setTagAndOperatorFilter = useCallback(
    (tags: HashTagCountedType[], operator: ConditionOperator) => {
      dispatch({ type: "UPDATE_QUERY", tags, tagOperator: operator });
      localStorage.setItem(
        FILTERS_LAST_CONTACT_FILTER_DATETIME,
        new Date().getTime().toString()
      );
      localStorage.setItem(FILTERS_TAGS_STORAGE_KEY, JSON.stringify(tags));
      localStorage.setItem(
        FILTERS_TAGS_OPERATOR_STORAGE_KEY,
        JSON.stringify(operator)
      );
      loadPage({ tags, tagOperator: operator, page: 1 });
    },
    [loadPage]
  );

  const setListIdAndOperatorFilter = useCallback(
    (ids: string[], operator: ConditionOperator) => {
      dispatch({ type: "UPDATE_QUERY", listIds: ids, listOperator: operator });
      localStorage.setItem(
        FILTERS_LAST_CONTACT_FILTER_DATETIME,
        new Date().getTime().toString()
      );
      localStorage.setItem(FILTERS_LISTS_STORAGE_KEY, JSON.stringify(ids));
      localStorage.setItem(
        FILTERS_LISTS_OPERATOR_STORAGE_KEY,
        JSON.stringify(operator)
      );
      loadPage({ listIds: ids, listOperator: operator, page: 1 });
    },
    [loadPage]
  );

  const setCollaboratorAndOperatorFilter = useCallback(
    (ids: string[], operator: ConditionOperator) => {
      dispatch({
        type: "UPDATE_QUERY",
        collaboratorIds: ids,
        collaboratorOperator: operator,
      });
      localStorage.setItem(
        FILTERS_LAST_CONTACT_FILTER_DATETIME,
        new Date().getTime().toString()
      );
      localStorage.setItem(
        FILTERS_COLLABORATOR_STORAGE_KEY,
        JSON.stringify(ids)
      );
      localStorage.setItem(
        FILTERS_COLLABORATOR_OPERATOR_STORAGE_KEY,
        JSON.stringify(operator)
      );
      loadPage({
        collaboratorIds: ids,
        collaboratorOperator: operator,
        page: 1,
      });
    },
    [loadPage]
  );

  const pageTitle = t("nav.menu.contacts");

  const resetFilters = useCallback(() => {
    dispatch({ type: "RESET_QUERY" });
    removeLocalStorage();
    loadPage({
      listIds: [],
      listOperator: DefaultOperatorValue,
      tags: [],
      tagOperator: DefaultOperatorValue,
      collaboratorIds: [],
      collaboratorOperator: DefaultOperatorValue,
      sort: state.sortParams,
      page: 1,
      filters: [],
    });
  }, [JSON.stringify(state.sortParams)]);

  const handleNewContact = useCallback(() => {
    dispatch({ type: "SHOW_NEW_CONTACT_FORM" });
  }, [dispatch]);

  const setVisible = useCallback(
    (visible: boolean) => {
      dispatch({
        type: visible ? "SHOW_NEW_CONTACT_FORM" : "HIDE_NEW_CONTACT_FORM",
      });
    },
    [dispatch]
  );

  const setProfileResult = useCallback(
    (profiles: ProfileSearchType[]) => {
      dispatch({ type: "UPDATE_PROFILES", profiles });
    },
    [dispatch]
  );

  return (
    <div className={`post-login`}>
      <PostLogin selectedItem={"Contacts"} />
      <Helmet title={t("nav.common.title", { page: pageTitle })} />
      <ContactsContext.Provider
        value={{
          ...getDefaultStateValue(),
          tagOperator: state.tagOperator,
          listOperator: state.listOperator,
          setTagAndOperatorFilter,
          setListIdAndOperatorFilter,
        }}
      >
        <div className={`contacts main`}>
          <ContactSidebar
            visible={state.filterDrawerVisible}
            existingFilters={state.filters}
            fields={state.fields.filter(
              (f) =>
                isNotLabelsColumn(f) &&
                isNotListsColumn(f) &&
                isNotCollaboratorColumn(f)
            )}
            tagFilters={state.tagFilters}
            setTagFilters={setTagFilters}
            numOfContacts={state.numOfContacts}
            numOfContactsTotal={state.numOfContactsUnfiltered}
            visibleSidebar={state.newContactFormVisible}
            setFilterValues={setFilterValuesList}
            isDisplayWalkThroughTooltip={displayPopup}
            setIsDisplayWalkThroughTooltip={(isDisplay: boolean) =>
              setDisplayPopup(isDisplay)
            }
            onHide={() => {
              dispatch({ type: "FILTERS.TOGGLE_DRAWER", visible: false });
            }}
            listIdFilters={state.listIdFilters}
            setListIdFilters={setListFilters}
            tagOperator={state.tagOperator}
            setTagAndOperatorFilter={setTagAndOperatorFilter}
            collaboratorFilters={state.collaboratorFilters}
            collaboratorOperator={state.collaboratorOperator}
            setCollaboratorFilters={setCollaboratorFilters}
            setCollaboratorAndOperatorFilter={setCollaboratorAndOperatorFilter}
            isIncludeCollaborator={
              state.fields.filter((f) => isNotCollaboratorColumn(f)).length !==
              state.fields.length
            }
          />
          <Dimmer.Dimmable
            dimmed
            className={`contact-list-wrap main-primary-column ${
              state.loading ? "blocked" : ""
            }`}
          >
            <ContactMain
              dispatch={dispatch}
              state={state}
              handleNewContact={handleNewContact}
              onQuickSearchChange={handleQuickSearchChange}
              onQuickSearch={executeQuickSearch}
              handleSort={handleSort}
              deleteProfile={deleteProfile}
              handlePageChange={handlePageChange}
              selectAllMachineState={selectAllMachineState}
              setProfileResult={setProfileResult}
              selectAllSend={selectAllSend}
              selectAllIds={selectAllIds}
              setFilters={setFilterValuesList}
              setListFilters={setListFilters}
              setTagFilters={setTagFilters}
              lists={lists}
              listsLoading={listsLoading}
              onListAdded={listAdded}
              resetFilters={resetFilters}
              refreshPage={refreshPage}
            />
            {(state.loading || state.deleteLoading) && (
              <Dimmer active inverted>
                <Loader inverted></Loader>
              </Dimmer>
            )}
            {
              <NewContact
                contactCreate={handleContactCreate}
                visible={state.newContactFormVisible}
                hideForm={() => setVisible(false)}
                staffList={staffList}
                profile={profile}
              />
            }
          </Dimmer.Dimmable>
        </div>
      </ContactsContext.Provider>
    </div>
  );
}

export default Contact;
