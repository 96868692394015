import React from "react";
import { Button, Image, Loader, Placeholder } from "semantic-ui-react";
import { baseName } from "../../../utility/baseName";
import { useImagePlaceholder } from "../../../lib/effects/useImagePlaceholder";

export function UploadImageView(props: {
  fileName: string;
  deletePending: boolean;
  uploadPending: boolean;
  previewSrc?: string;
  disabled?: boolean;
  onDelete: (e: React.MouseEvent) => Promise<void>;
  large?: boolean;
}) {
  let {
    deletePending,
    fileName,
    onDelete,
    previewSrc,
    uploadPending,
    disabled = false,
    large = false,
  } = props;
  const displayFileName = fileName ? baseName(fileName) : "";

  const {
    loading: imageSrcLoading,
    failed,
    loaded: imageLoaded,
  } = useImagePlaceholder(previewSrc);
  return (
    <div
      className={`image-item ${displayFileName ? "file" : ""} ${
        large ? "large" : ""
      }`}
    >
      {deletePending ||
      uploadPending ||
      (previewSrc && imageSrcLoading && !failed) ? (
        <Loader active inline size={"small"} />
      ) : (
        !disabled && (
          <Button icon="times" className="delete-img" onClick={onDelete} />
        )
      )}
      {previewSrc &&
        (imageLoaded && !failed ? (
          <Image src={previewSrc} />
        ) : (
          <Placeholder>
            <Placeholder.Image />
          </Placeholder>
        ))}
      {displayFileName && !previewSrc && (
        <span className="fileName">{displayFileName}</span>
      )}
    </div>
  );
}
