import {
  IndividualListAction,
  IndividualListState,
} from "./individualListReducer";
import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import {
  isContactOwnerFilter,
  updateContactOwnerFilter,
} from "../../../ContactMain";
import { QuickSearch } from "../../../../component/Contact/QuickSearch";
import { ContactOwnerFilter } from "../../../../component/Contact/Filter/ContactOwnerFilter";
import { TagsFilter } from "../../../../component/Contact/Filter/TagsFilter";
import { Button, Label } from "semantic-ui-react";
import { HashTagCountedType } from "../../../../types/ConversationType";
import { ConditionOperator } from "../../hooks/ContactsStateType";

export function GridControls(props: {
  dispatch: (action: IndividualListAction) => void;
  state: IndividualListState;
  setTagAndOperatorFilter: (
    tags: HashTagCountedType[],
    operator: ConditionOperator
  ) => void;
}) {
  const { dispatch, state } = props;
  const { t } = useTranslation();

  const handleQuickSearchChange = useCallback(
    (text: string) => {
      dispatch({ type: "QUICK_SEARCH_UPDATE", search: text });
    },
    [dispatch]
  );

  async function handleQuickSearchExecute() {
    dispatch({ type: "QUICK_SEARCH_EXECUTE" });
  }

  const anyFiltersApplied =
    state.searchFilters.length + state.tagFilters.length > 0;
  const sidebarFiltersCount =
    state.searchFilters.length + (state.tagFilters.length > 0 ? 1 : 0);
  const anySidebarFilterApplied = sidebarFiltersCount > 0;

  return (
    <div className="grid-controls">
      <div className="control-group flexible">
        <QuickSearch
          onChange={handleQuickSearchChange}
          onSearchExecute={handleQuickSearchExecute}
        />
      </div>
      <div className="control-group collapsible">
        <ContactOwnerFilter
          values={state.searchFilters
            .filter(isContactOwnerFilter)
            .reduce<string[]>((acc, next) => [...acc, ...next.filterValue], [])}
          onChange={(values) => {
            dispatch({
              type: "SEARCH_FILTERS_UPDATE",
              filters: updateContactOwnerFilter(state.searchFilters, values),
            });
          }}
        />
      </div>
      <div className="control-group collapsible">
        <TagsFilter
          isSupportMultipleCondition={true}
          tagFilters={state.tagFilters}
          setTagAndOperatorFilter={props.setTagAndOperatorFilter}
          onTagFiltersChanged={(filters) => {
            dispatch({
              type: "TAG_FILTERS_UPDATED",
              tags: filters,
              tagOperator: "And",
            });
          }}
        />
      </div>
      <div className="control-group button">
        <Button
          className={`pinnable ${anySidebarFilterApplied ? "pinned" : ""}`}
          active={state.filtersVisible}
          onClick={() => {
            dispatch({
              type: "FILTERS_TOGGLE",
              visible: !state.filtersVisible,
            });
          }}
        >
          <i className={"ui icon filters-toggle"} />
          {t("profile.contacts.actions.moreFilters")}
          {anySidebarFilterApplied && <Label>{sidebarFiltersCount}</Label>}
        </Button>
      </div>
      {anyFiltersApplied && (
        <div className="control-group button">
          <Button
            className={`pinnable`}
            onClick={() => {
              dispatch({ type: "FILTERS_RESET" });
            }}
          >
            {t("profile.contacts.actions.clearFilters")}
          </Button>
        </div>
      )}
    </div>
  );
}
