import { NewContactType } from "../../../types/ContactType";
import { CreateProfileCustomExcludedFieldConfig } from "../../../config/ProfileFieldMapping";
import { FieldValue } from "./NewContact";
import { useValidateContact } from "../validation/useValidateContact";
import { useCustomProfileFields } from "../../../container/Contact/hooks/useCustomProfileFields";
import { ProfileType } from "../../../types/LoginType";
import { useState } from "react";
import { useFlashMessageChannel } from "../../BannerMessage/flashBannerMessage";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useRouteConfig from "../../../config/useRouteConfig";
import { useAppDispatch } from "../../../AppRootContext";

export function useEditContactApi(props: {
  editMode: boolean;
  isAllowToDuplicateRedirect: boolean;
  setErrorMessages: (errors: Record<string, any>) => void;
  setFieldValues: (values: Record<string, string>) => void;
  setGeneralErrorMessage: (error: string) => void;
  onContactCreated: (keepVisible: undefined | boolean) => void;
  hideForm: () => void;
  profile: ProfileType;
  gateway: {
    createContact: (param: NewContactType) => Promise<ProfileType>;
    updateContact: (id: string, param: NewContactType) => Promise<ProfileType>;
  };
}) {
  const customProfileFields = useCustomProfileFields();
  const { validateContactFields } = useValidateContact();
  const [loading, isLoading] = useState(false);
  const flash = useFlashMessageChannel();
  const { t } = useTranslation();
  const history = useHistory();
  const routeConfig = useRouteConfig();
  const loginDispatch = useAppDispatch();

  const createContact = async (
    fieldValues: Record<string, string>,
    keepVisible?: boolean
  ) => {
    let param: NewContactType = {};
    let valuesValidated: FieldValue;
    if (fieldValues["Subscriber"] === undefined) {
      fieldValues["Subscriber"] = "true";
    }
    try {
      valuesValidated = await validateContactFields(customProfileFields.fields)(
        fieldValues
      );
      props.setErrorMessages({});
    } catch (e) {
      props.setErrorMessages({ [e.path]: e.message });
      document.getElementById(`${e.path}`)?.scrollIntoView();
      return;
    }

    if (customProfileFields.fields.length > 0) {
      if (valuesValidated["PhoneNumber"]) {
        param = {
          ...param,
          whatsAppPhoneNumber: valuesValidated["PhoneNumber"],
        };
      }
      if (valuesValidated["Email"]) {
        param = {
          ...param,
          email: valuesValidated["Email"],
        };
      }
      const userProfileFields = Object.keys(valuesValidated)
        .filter(
          (key) =>
            !CreateProfileCustomExcludedFieldConfig.find(
              (field) => key.toLowerCase() === field.toLowerCase()
            )
        )
        .map((fieldName) => ({
          customFieldName: fieldName || "",
          customValue: valuesValidated[fieldName],
          customFieldId: customProfileFields.fields.find(
            (f) => f.fieldName === fieldName
          )?.id,
        }))
        .filter((f) => f.customFieldId !== undefined);
      param = {
        ...param,
        lastName: valuesValidated["lastName"] ?? "",
        firstName: valuesValidated["firstName"] ?? "",
        userProfileFields,
      };
    }

    try {
      isLoading(true);
      const result = await props.gateway.createContact(param);
      if (!keepVisible) {
        props.hideForm();
      }
      props.setFieldValues({});
      flash(t("flash.profile.created"));
      props.onContactCreated(keepVisible);
      props.setGeneralErrorMessage("");
    } catch (e) {
      const errStr = e.message;
      console.error(`save contact error ${e}`);
      try {
        const errJSON = JSON.parse(errStr);
        errorHandling(errJSON["message"], errJSON["userProfileId"]);
      } catch (err) {
        errorHandling(errStr);
      }
    } finally {
      isLoading(false);
    }
  };

  const errorHandling = (errMsg: string, userProfileId?: string) => {
    const BACKEND_DUPLICATE_ERROR = "Duplicated PhoneNumber";
    if (errMsg === BACKEND_DUPLICATE_ERROR) {
      props.setErrorMessages({
        PhoneNumber: t("form.profile.field.phone.error.duplicate"),
      });
      flash(t("flash.contact.duplicated"));
      props.setGeneralErrorMessage("");
      if (userProfileId && props.isAllowToDuplicateRedirect) {
        history.push(routeConfig.routeTo(`/profile/${userProfileId}`));
        return;
      }
    } else {
      props.setGeneralErrorMessage(errMsg);
    }
  };

  const updateContact = async (fieldValues: Record<string, string>) => {
    let param: NewContactType = {};
    let valuesValidated: FieldValue = { ...fieldValues };

    if (customProfileFields.fields.length > 0) {
      if (valuesValidated["PhoneNumber"]) {
        param = {
          ...param,
          whatsAppPhoneNumber: valuesValidated["PhoneNumber"],
        };
      }
      if (valuesValidated["Email"]) {
        param = {
          ...param,
          email: valuesValidated["Email"],
        };
      }
      const userProfileFields = Object.keys(valuesValidated)
        .filter(
          (key) =>
            !CreateProfileCustomExcludedFieldConfig.find(
              (field) => key.toLowerCase() === field.toLowerCase()
            )
        )
        .map((fieldName) => {
          return {
            customFieldName: fieldName || "",
            customValue: valuesValidated[fieldName],
            customFieldId: customProfileFields.fields.find(
              (f) => f.fieldName === fieldName
            )?.id,
          };
        })
        .filter((f) => f.customFieldId !== undefined);
      param = {
        ...param,
        userProfileFields,
      };
    }

    try {
      isLoading(true);
      let profileUpdate: ProfileType = props.profile;
      const updatePayload = {
        firstName: valuesValidated["firstName"] ?? "",
        lastName: valuesValidated["lastName"] ?? "",
        ...param,
      };

      const result = await props.gateway.updateContact(
        props.profile.id,
        updatePayload
      );
      profileUpdate = { ...profileUpdate, ...result };

      props.hideForm();
      props.setGeneralErrorMessage("");
      props.setErrorMessages({});
      loginDispatch({
        type: "PROFILE_UPDATED",
        profile: {
          ...profileUpdate,
        },
      });

      if (props.editMode) {
        flash(t("flash.profile.updated"));
      }

      props.onContactCreated(undefined);
    } catch (e) {
      const errStr = e.message;
      console.error(`save contact error ${e}`);
      try {
        const errJSON = JSON.parse(errStr);
        errorHandling(errJSON["message"]);
      } catch (err) {
        errorHandling(errStr);
      }
    } finally {
      isLoading(false);
    }
  };

  return {
    createContact,
    updateContact,
    loading,
  };
}
