import { StaffListType } from "../../component/Chat/types";
import { URL } from "../apiRequest";
import { GET_STAFF_PROFILE_PIC } from "../apiPath";
import { StaffTypeDeprecated } from "../../types/StaffTypeDeprecated";
import { StaffType } from "../../types/StaffType";

export function getUserPicUrl(
  user: StaffType | StaffListType | StaffTypeDeprecated
): string | undefined {
  if (!user.profilePicture?.id) {
    return;
  }
  return `${URL}/${GET_STAFF_PROFILE_PIC}/${user.profilePicture?.profilePictureId}`;
}
