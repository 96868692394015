import React, { ReactNode, useContext } from "react";
import { AudienceType } from "../../types/BroadcastCampaignType";
import { Button } from "semantic-ui-react";
import BroadcastContext from "../Broadcast/BroadcastContext";
import { useConditionNameLocale } from "../Contact/locaizable/useConditionNameLocale";
import { useTranslation } from "react-i18next";
import { FilterConfigType } from "../../types/FilterConfigType";
import moment from "moment";
import { DATE_FORMAT_MOMENT } from "./FilterValueInput";

export default function FilterSelectedItem(props: {
  audience: AudienceType;
  index: number;
  updateSelectedItem: Function;
  type: string;
  filterList: FilterConfigType[];
  deleteFilter?: Function;
}) {
  const {
    audience,
    index,
    updateSelectedItem,
    type,
    filterList,
    deleteFilter,
  } = props;
  const { filterValue, filterCondition, fieldName } = audience;
  const { audienceTypes, broadcastDispatch } = useContext(BroadcastContext);
  let fieldDisplayName = "";
  let fieldValueType = "";
  let filterDisplayName = "";
  const { t } = useTranslation();
  const { conditionNameMap } = useConditionNameLocale();

  let filterDisplayValue: string | ReactNode[] = filterValue ?? "?";
  let singleValueRaw = filterValue.slice(0).shift() ?? "";

  const filterAppliedConfig = filterList.find(
    (filter) =>
      filter.fieldName === fieldName || filter.fieldDisplayName === fieldName
  );

  if (fieldName === "importfrom" || !filterAppliedConfig) {
    return null;
  }

  fieldDisplayName = (filterAppliedConfig.fieldDisplayName as string) || "";
  fieldValueType = filterAppliedConfig.fieldType || "";

  if (
    Array.isArray(filterValue) &&
    filterAppliedConfig.fieldOptions !== undefined
  ) {
    let valueNames = filterValue.map((value: any) => {
      let defaultDisplayValue = value.toString();

      if (!filterAppliedConfig.fieldOptions) {
        return defaultDisplayValue;
      }
      const option = filterAppliedConfig.fieldOptions.find(
        (optionConfig) => optionConfig.value === value
      );

      if (option === undefined || option.text === undefined) {
        return defaultDisplayValue;
      }

      return option.text;
    });
    if (fieldValueType.toLowerCase() === "boolean") {
      filterDisplayValue =
        singleValueRaw.toLowerCase() === "true"
          ? t("profile.field.type.boolean.true")
          : t("profile.field.type.boolean.false");
    } else if (["date", "datetime"].includes(fieldValueType.toLowerCase())) {
      filterDisplayValue = filterDisplayValue
        ? moment(singleValueRaw).format(DATE_FORMAT_MOMENT)
        : "";
    } else {
      filterDisplayValue = [...valueNames];
    }
  }

  if (filterAppliedConfig.fieldType) {
    const key = Object.keys(
      conditionNameMap[filterAppliedConfig.fieldType]
    ).find((key) => key.toLowerCase() === filterCondition.toLowerCase());
    if (key) {
      filterDisplayName = conditionNameMap[filterAppliedConfig.fieldType][key];
      if (
        filterDisplayName === "is known" ||
        filterDisplayName === "is unknown"
      ) {
        filterDisplayValue = "";
      }
    }
  }

  const handleAudienceTypeCounter = () => {
    if (deleteFilter) {
      deleteFilter(index);
    }
    broadcastDispatch({
      type: "UPDATE_FILTER",
      audienceTypes:
        audienceTypes &&
        audienceTypes.filter((audienceType, id) => id !== index),
    });
  };

  const filterItemClick = () => {
    updateSelectedItem(audience);
  };

  return (
    <FilterSelectedItemView
      onClick={filterItemClick}
      onDelete={handleAudienceTypeCounter}
      fieldName={fieldName}
      fieldDisplayName={fieldDisplayName}
      filterDisplayName={filterDisplayName}
      filterDisplayValue={filterDisplayValue}
    />
  );
}

export function FilterSelectedItemView(props: {
  fieldDisplayName: string;
  filterDisplayName: string;
  filterDisplayValue: string | ReactNode[];
  fieldName: string;
  onClick: () => void;
  onDelete: () => void;
  className?: string;
}) {
  const {
    fieldDisplayName,
    fieldName,
    filterDisplayName,
    filterDisplayValue,
    onClick,
    onDelete,
    className,
  } = props;

  return (
    <div className={`filter-condition ${className ?? ""}`}>
      <div className="filter-content">
        <div className="filter-description" onClick={onClick}>
          {fieldDisplayName ? (
            <div className="filter-name">
              <div className="filter-name-wrap">
                {fieldDisplayName} {filterDisplayName}
              </div>{" "}
              {Array.isArray(filterDisplayValue) ? (
                filterDisplayValue.map((v, idx) => {
                  const appendix =
                    idx < filterDisplayValue.length - 1 ? (
                      <span className={"comma"}>,</span>
                    ) : (
                      <></>
                    );
                  return (
                    <span className="contain-word" key={idx}>
                      <span className={"value"}>{v}</span>
                      {appendix}
                    </span>
                  );
                })
              ) : (
                <span className="contain-word">{filterDisplayValue}</span>
              )}
            </div>
          ) : (
            <div className="filter-name">#{fieldName}</div>
          )}
        </div>
        <Button icon="times" onClick={onDelete} />
      </div>
    </div>
  );
}
