export const NewContactTypeRequestFieldMapping = {
  name: "displayName",
  email: "Email",
  whatsApp: "whatsAppPhoneNumber",
  phoneNumber: "whatsAppPhoneNumber",
};
export const DropdownType = ["options", "travisuser", "channel", "importfrom"];

export const CustomFieldTypeList = [
  "singlelinetext",
  "multilinetext",
  "number",
  "phonenumber",
  "email",
  "options",
  "boolean",
  "datetime",
  "date",
  "travisuser",
  "channel",
];

export const CustomFieldTypeListMapping = {
  singlelinetext: "Single-Line Text",
  multilinetext: "Multi-Line Text",
  number: "Number",
  phonenumber: "Phone Number",
  email: "Email",
  options: "Dropdown Select",
  datetime: "Date Time",
  boolean: "True / False",
  travisuser: "Company Staff",
  channel: "Channel",
  date: "Date",
};
